import React from "react";

import {ReactComponent as TopShape} from "../../Assests/Icons/shapes/PopUpShapeTop.svg";
import {ReactComponent as BottomShape} from "../../Assests/Icons/shapes/PopUpShapeBottom.svg";
import {ReactComponent as CloseIcon} from "../../Assests/Icons/close.svg";
import {ReactComponent as LineIcon} from "../../Assests/Icons/line.svg";

import "./Styles.scss"
import useMatchMedia from "../../Hooks/useMatchMedia";
import {MOBILE_MAX_WIDTH_QUERY} from "../../Constants/Values";

type Props = {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    children?: React.ReactNode;
    callback?: () => void;
}

const CustomPopup = ({
                         isOpen,
                         setIsOpen,
                         children,
                         callback
                     }: Props) => {

    const {match: isMobileDevice} = useMatchMedia(MOBILE_MAX_WIDTH_QUERY);

    const handleClick = (e: any) => {
        if (e.target.id === "otp-popup") {
            setIsOpen(false)
        }
    }

    return (
        <section
            id="otp-popup"
            className={[
                "popup",
                "panel panel-modal",
                isOpen ? "active" : "",
            ].join(" ")}
            onClick={handleClick}
        >
                <div className="login-modal-inner">
                    <div
                        className="panel-modal-inner shadow"
                    >
                        {/*<div className="background-image">*/}
                        {/*    <TopShape className="background-image-top"/>*/}
                        {/*    <BottomShape className="background-image-bottom"/>*/}
                        {/*</div>*/}
                        {
                            isMobileDevice ? (
                                <div className='flex items-center justify-center mt-[-20px]'>
                                    <LineIcon
                                        onClick={() => {
                                            callback && callback()
                                            setIsOpen(false)
                                        }}
                                    />
                                </div>
                            ): (
                                <div className="close-icon-button">
                                    <CloseIcon onClick={() => {
                                        callback && callback()
                                        setIsOpen(false)
                                    }}
                                               className="cursor-pointer text-gray-darker"
                                    />
                                </div>
                            )
                        }

                        <div className="popup-content text-center">
                            {children}
                        </div>
                    </div>
                </div>

            <div
                className="panel-overlay"
            ></div>
        </section>
    )
}

export default CustomPopup