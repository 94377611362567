import { createContext, useContext, useEffect, useState } from 'react';

import { AccountInterface } from '../Models/Account';

interface ContextState {
  setUserAccount: (account: AccountInterface) => void;
  userAccountDetails: AccountInterface;
  isAuthenticated: () => boolean;
}

type Props = {
  children: React.ReactNode;
};

const AppContext = createContext({} as ContextState);

const AppProvider = ({ children }: Props) => {
  //TODO: reftch from user account endpoint
  // const getInitialAccountState = () => Storage.get("userAccount") || null;
  const [userAccountDetails, setUserAccountDetails] =
    useState<AccountInterface>({});

  const setUserAccount = (account: AccountInterface) => {
    setUserAccountDetails(account);
    // //TODO: Remove this
    // Storage.saveToLocalStorage("userAccount", account);
  };
  const isAuthenticated = () => {
    return !!userAccountDetails?.email;
  };

  return (
    <AppContext.Provider
      value={{
        setUserAccount,
        userAccountDetails,
        isAuthenticated,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppData = (): ContextState => {
  const appData = useContext(AppContext);
  if (appData === undefined) {
    throw new Error('useAppData must be used within a AppProvider');
  }
  return appData;
};

export default AppProvider;
