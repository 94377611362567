import React, {useEffect, useRef, useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input';

import TermsOfService from '../Terms';

import AssetsService from '../../Services/Assets/AssetsService';
import AuthService from '../../Services/Auth/AuthService';

import {EMAIL_PATTERN, TOAST_BOTTOM_CENTER, TOAST_DURATION} from '../../Constants/Values';
import Toast from '../../Utils/ToastHandler';

import './Styles.scss';

type FormData = {
    phone: string;
    email: string;
};

const Register = () => {
    const phoneInputRef = useRef<any>(null);
    const {t, i18n} = useTranslation();

    const {
        control,
        register,
        handleSubmit,
        formState: {errors, isValid, isDirty},
    } = useForm<any>({
        mode: 'onChange',
    });

    const [termsAndConditions, setTermsAndConditions] = useState({
        terms: false,
        credit: false,
    });
    const [isTermsOpened, setIsTermsOpened] = useState(false);

    const [failedTerms, setFailedTerms] = useState(false);
    const [failedCredit, setFailedCredit] = useState(false);
    const [region, setRegion] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [phoneStep, setPhoneStep] = useState(1);

    const getRegionHandler = () => {
        AssetsService.getRegion()
            .then((res: any) => {
                setRegion(res?.region);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const submitForm: SubmitHandler<FormData> = (data: FormData) => {
        if (phoneStep === 1) {
            setPhoneStep(2);
            return;
        }

        setFailedTerms(!termsAndConditions.terms);
        setFailedCredit(!termsAndConditions.credit);
        if (!termsAndConditions.terms || !termsAndConditions.credit) {
            Toast.error(
                t('Error-Accept-Terms-And-Credits'),
                TOAST_DURATION,
                TOAST_BOTTOM_CENTER
            );
            return;
        }

        const phonePayload = data.phone.replace(/\D/g, '');

        setLoading(true);
        AuthService.registerPhoneNumber(phonePayload, data.email)
            .then((res) => {
                setLoading(false);
                Toast.success(
                    t('Registration-Success'),
                    TOAST_DURATION,
                    TOAST_BOTTOM_CENTER
                );
            })
            .catch((err) => {
                const error = err?.response?.data?.Error?.Message || '';

                setLoading(false);
                setPhoneStep(1);

                if (error && error === 'RegistrationError') {
                    Toast.error(
                        t('Registration-Error-Register'),
                        TOAST_DURATION,
                        TOAST_BOTTOM_CENTER
                    );
                } else if (error && error === 'PartialRegistrationError') {
                    Toast.success(
                        t('Registration-Success'),
                        TOAST_DURATION,
                        TOAST_BOTTOM_CENTER
                    );
                } else {
                    Toast.error(
                        error && i18n.exists(error) ? t(error) : t('generic-error'),
                        TOAST_DURATION,
                        TOAST_BOTTOM_CENTER
                    );
                }
            });
    };

    useEffect(() => {
        getRegionHandler();
    }, []);

    return (
        <div className="register-container">
            <div className="register-inner py-4">
                <h4 className="login-registration-title">Register</h4>


                <span
                    className="register-subheading mb-2 text-center">{phoneStep === 1 ? t("Registration-Step-One-Title") : t("Registration-Step-Two-Title")}</span>
                <div
                    className={`d-flex align-items-center justify-content-center flex-column ${phoneStep === 1 && "mb-5"}`}>
                    <Controller
                        control={control}
                        name="phone"
                        rules={{
                            required: {
                                value: true,
                                message: 'Phone is required',
                            },
                            validate: isPossiblePhoneNumber,
                        }}
                        render={({field: {onChange, value}}) => {
                            return (
                                <div className="login-registration-popup">
                                    {
                                        phoneStep === 1 ? <>
                                            <PhoneInput
                                                ref={phoneInputRef}
                                                addInternationalOption={false}
                                                value={value}
                                                onChange={onChange}
                                                defaultCountry={region ? region : 'MT'}
                                                id="register-phone-input"
                                                placeholder={'Phone Number'}
                                                limitMaxLength
                                            />
                                            <button
                                                type="submit"
                                                id="register-form-button"
                                                disabled={!isDirty || !isValid}
                                                onClick={handleSubmit(submitForm)}
                                            >
                                                {loading ? 'Loading ' : 'Next '}
                                            </button>
                                        </> : <>
                                            <input
                                                placeholder={"Enter Email"}
                                                type="email"
                                                className="email-input"
                                                {...register("email", {
                                                    required: {
                                                        value: true,
                                                        message: "Email is required",
                                                    },
                                                    pattern: {
                                                        value: EMAIL_PATTERN,
                                                        message: "Invalid email address",
                                                    },
                                                })}
                                            />
                                            <button
                                                type="submit"
                                                id="register-form-button"
                                                disabled={!isDirty || !isValid}
                                                onClick={handleSubmit(submitForm)}
                                            >
                                                {loading ? 'Loading ' : 'Register '}
                                            </button>
                                        </>}

                                </div>
                            );
                        }}
                    />

                    {errors.phone && (
                        <div className="text-error " id="phone-validation-error">
                            {errors['phone'].message + ''}
                        </div>
                    )}
                    {errors.phone?.type === 'validate' && (
                        <div className="text-error " id="phone-invalid-error">
                            Invalid phone number
                        </div>
                    )}
                    {errors.email && (
                        <div className="text-error " id="email-validation-error">
                            {errors['email'].message + ''}
                        </div>
                    )}

                    {phoneStep === 2 &&
                        <div className="login-register-terms-and-credit">
                            <div>
                                <span className="text-error pe-1">*</span>
                                <div className="form-check form-check-inline">
                                    <input
                                        className={
                                            failedTerms ? 'form-check-input error' : 'form-check-input'
                                        }
                                        type="checkbox"
                                        id="login-registration-terms-checkbox"
                                        onClick={() => {
                                            setTermsAndConditions({
                                                ...termsAndConditions,
                                                terms: !termsAndConditions.terms,
                                            });
                                        }}
                                        value="option1"
                                    />
                                    <label
                                        className={
                                            failedTerms
                                                ? 'form-check-label login-register-terms-and-conditions  error'
                                                : 'form-check-label login-register-terms-and-conditions text-white'
                                        }
                                        htmlFor="login-registration-terms-checkbox"
                                    >
                                        Accept
                                        <span
                                            className={
                                                failedTerms
                                                    ? 'text-white text-decoration-underline login-register-terms-and-conditions  error ps-1'
                                                    : 'text-decoration-underline login-register-terms-and-conditions ps-1'
                                            }
                                            onClick={() => setIsTermsOpened(!isTermsOpened)}
                                        >
                  Terms and Conditions
                </span>
                                    </label>
                                </div>
                            </div>

                            <div>
                                <span className="text-error pe-1">*</span>
                                <div className="form-check form-check-inline">
                                    <input
                                        className={
                                            failedCredit
                                                ? 'form-check-input error'
                                                : 'form-check-input text-white'
                                        }
                                        type="checkbox"
                                        id="credit-checkbox"
                                        onClick={() => {
                                            setTermsAndConditions({
                                                ...termsAndConditions,
                                                credit: !termsAndConditions.credit,
                                            });
                                        }}
                                        value="option2"
                                    />
                                    <label
                                        className={
                                            failedCredit
                                                ? 'form-check-label login-register-credit error'
                                                : 'form-check-label login-register-credit text-white'
                                        }
                                        htmlFor="credit-checkbox"
                                    >
                                        Receive messages and free credits
                                    </label>
                                </div>
                            </div>

                        </div>}
                </div>

                <TermsOfService
                    id="termsOfService"
                    isOpened={isTermsOpened}
                    setIsOpened={setIsTermsOpened}
                />
            </div>
        </div>
    );
};

export default Register;
