import TokenHandler from "./TokenHandler";

type WindowWithDataLayer = Window & {
    dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

export const gtagEventHandler = (parameters: any, name: string) => {

    const externalReference = TokenHandler?.getExternalRef();
    return new Promise((resolve, reject) => {
        window.dataLayer.push({
            event: name,
            userExternalReference: externalReference || "",
            timestamp: new Date().toISOString(),
            deviceType: window.innerWidth > 768 ? "desktop" : "mobile",
            ...parameters
        });
        resolve(true);
    })
}