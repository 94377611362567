import FactoryService from '../FactoryService';
import AccountingBaseService from "../AccountingBaseService";

const factoryService = new FactoryService();

class ProfileService {
    private BALANCE_ENDPOINT = 'api/Credits/balance';
    private TRANSACTIONS_ENDPOINT = 'api/Transactions';
    private TOPUP_ENDPOINT = 'api/Payment/topup';
    private INVOICE_ENDPOINT = 'api/Invoices';

    async getBalance() {
        const result = await (
            await factoryService.create(AccountingBaseService)
        ).getAsJson(this.BALANCE_ENDPOINT, true);

        if (result) {
            return result;
        }

        return null;
    }

    async getTransactions(searchTerm: string | null) {
        let queryParam = '?';
        if (searchTerm !== null && searchTerm !== '') {
            queryParam += `Search=${searchTerm}`;
        }

        if (queryParam === '?') {
            queryParam = '';
        }

        const result = await (
            await factoryService.create(AccountingBaseService)
        ).getAsJson(`${this.TRANSACTIONS_ENDPOINT}${queryParam}`, true);

        if (result) {
            return result;
        }

        return null;
    }

    async getTopUpPreview(country: string) {
        const result = await (
            await factoryService.create(AccountingBaseService)
        ).getAsJson(`${this.TOPUP_ENDPOINT}/${country}/preview`, true);

        if (result) {
            return result;
        }

        return null;
    }

    async topUp(data: any) {
        const result = await (
            await factoryService.create(AccountingBaseService)
        ).postAsJson(this.TOPUP_ENDPOINT, data, true);

        if (result) {
            return result;
        }

        return null;
    }

    async getInvoice(transactionReference: string) {
        const result = await (
            await factoryService.create(AccountingBaseService)
        ).getAsJson(`${this.INVOICE_ENDPOINT}/${transactionReference}/preview`, true);

        if (result) {
            return result;
        }

        return null;
    }

}

export default new ProfileService();
