import {useEffect, useState} from "react";

import mobileTelegramChat from "../../../Assests/Images/mobile-our-features.png";
import {ReactComponent as TrophyIcon} from "../../../Assests/Icons/trophy.svg";
import {ReactComponent as GraphIcon} from "../../../Assests/Icons/growth-chart.svg";
import {ReactComponent as WinIcon} from "../../../Assests/Icons/win-strike.svg";
import {ReactComponent as TimerIcon} from "../../../Assests/Icons/flash-timer.svg";

import {fetchLayoutContent} from "../../../Services/Blog/BlogService";

import "./Styles.scss";

type Props = {
    featureData: any;
}
const OurFeatures = ({featureData}: Props) => {

    return (
        <div className="container">
            <div className="container">
                <div className="xl:pt-10 xl:mt-44 xl:mx-24">
                    <div className="flex items-center flex-col">
                        <h5 className="text-4xl text-blue-dark font-black font-sans">{
                            featureData?.Section2Title || ""
                        }</h5>
                        <h5 className="text-xl font-sans font-thin text-light-black mb-20 text-center">{
                            featureData?.Section2Content || ""
                        }</h5>

                        <div className="flex items-center justify-evenly flex-col xl:flex-row mb-14 w-full">
                            <div className="flex flex-col xl:w-1/3">

                                <div className="flex flex-col mb-10 w-full items-center xl:items-start">
                                    <TrophyIcon className="mb-6"/>
                                    <h5 className="text-4xl font-black font-sans text-light-black">{
                                        featureData?.CupTitle || ""
                                    }</h5>
                                    <h5 className="text-lg font-sans font-thin text-light-black">{
                                        featureData?.CupContent || ""
                                    }</h5>
                                </div>

                                <div className="flex flex-col w-full xl:w-80 items-center xl:items-start">
                                    <GraphIcon className="mb-6 h-full"/>
                                    <h5 className="text-4xl font-black font-sans text-light-black small-screen-text-center">{
                                        featureData?.RateTitle || ""
                                    }</h5>
                                    <h5 className="text-lg font-sans font-thin text-light-black">{
                                        featureData?.RateContent || ""
                                    }</h5>
                                </div>

                            </div>

                            <div className="flex items-center justify-center w-1/3 mb-10 d-none d-lg-flex">
                                <img src={mobileTelegramChat} alt="football" className="scale-125"/>
                            </div>

                            <div className="flex flex-col xl:w-1/3">

                                <div className="flex flex-col xl:ms-16 mb-10 w-full items-center xl:items-start mt-10 xl:mt-1">
                                    <TimerIcon className="mb-6"/>
                                    <h5 className="text-4xl font-black font-sans text-light-black small-screen-text-center">{
                                        featureData?.TimeTitle || ""
                                    }</h5>
                                    <h5 className="text-lg font-sans font-thin text-light-black">{
                                        featureData?.TimeContent || ""
                                    }</h5>
                                </div>

                                <div className="flex flex-col xl:ms-16 w-full xl:w-80 items-center xl:items-start">
                                    <WinIcon className="mb-6 h-full"/>
                                    <h5 className="text-4xl font-black font-sans text-light-black small-screen-text-center">{
                                        featureData?.ThunderBoltTitle || ""
                                    }</h5>
                                    <h5 className="text-lg font-sans font-thin text-light-black">{
                                        featureData?.ThunderBoltContent || ""
                                    }</h5>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurFeatures;