import "./Styles.scss";

type BtnProps = {
    type?: "submit" | "button" | "reset";
    onClick?: () => void;
    disabled?: boolean;
    loading?: boolean;
    rootProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
    customClass?: string;
    id?: string;
    isCircle?: boolean;
    isSuccess?: boolean;
};

type BtnTextProps = {
    text: string;
    children?: never;
} & BtnProps;

type BtnChildrenProps = {
    children: React.ReactNode;
    text?: never;
} & BtnProps;

type Props = BtnTextProps | BtnChildrenProps;

const Button = ({
                    type = "button",
                    disabled,
                    text,
                    children,
                    onClick,
                    loading,
                    rootProps,
                    customClass,
                    isCircle,
                    id,
                }: Props) => {
    return (
        <button
            id={id}
            type={type}
            className={isCircle ? `${customClass} btn-circle` : customClass}
            disabled={disabled}
            onClick={onClick}
        >
            {children ? children : text}
        </button>
    );
};

Button.defaultProps = {
    customClass: "btn btn-lg btn-primary",
    isCircle: false,
};

export default Button;
