import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

import PrivateWrapper from "../../Layouts/PrivateWrapper";
import telegramLogo from "../../Assests/Images/telegram.png";
import TokenHandler from "../../Utils/TokenHandler";
import AuthService from "../../Services/Auth/AuthService";

import {TELEGRAM_URLS} from "../../Constants/Values";

import "./Styles.scss";
import {Spin} from "antd";

const {
    REACT_APP_ENV
} = (window as any).__env__;

const Onboarding = () => {
    const {t} = useTranslation();
    const externalReference = TokenHandler?.getExternalRef();
    const [loading, setLoading] = React.useState<boolean>(false);

    const checkStatusHandler = () => {
        setLoading(true);
        AuthService.refreshToken().then((res) => {
            window.location.reload();
            setLoading(false);
        }).catch((e) => {
            setLoading(false)
            console.log(e);
        })
    }

    useEffect(() => {
        if (!externalReference) return;

        checkStatusHandler();
    }, []);

    const onboardingHandler = () => {
        return <div className="mb-2 mt-4 successcontent">
            <h4 className="subheading fw-bold success">Success!</h4>

            <span className="step-three-sub-title">
                	Our tips are sent via Telegram click below.
            </span>

            <div className="success">
                <button
                    className="telegram-button"
                    type="button"
                    onClick={() => telegramLinkHandler()}
                >
                    <i className="fab fa-telegram-plane me-2"></i>
                    Start receiving tips
                    <img
                        src={telegramLogo}
                        alt="telegram"
                        className="ms-2"
                        style={{width: "1.5rem"}}
                    />
                </button>
            </div>

            <div className="p-2 text-center">
                Once Telegram registration is completed click refresh below to access the portal
                <br/>
                <button
                    className="btn btn-primary rounded"
                    type="button"
                    onClick={() => checkStatusHandler()}
                >
                    Refresh
                </button>
            </div>
        </div>
    }

    useEffect(() => {
        if (!externalReference) return;

        checkStatusHandler();
    }, []);

    const telegramLinkHandler = () => {
        if (REACT_APP_ENV === "dev") {
            window.open(TELEGRAM_URLS.dev, "_blank");
        } else if (REACT_APP_ENV === "staging") {
            window.open(TELEGRAM_URLS.staging, "_blank");
        } else if (REACT_APP_ENV === "qa") {
            window.open(TELEGRAM_URLS.qa, "_blank");
        } else {
            window.open(TELEGRAM_URLS.prod, "_blank");
        }
    }

    return (
        <PrivateWrapper>
            {!loading ? onboardingHandler() : <div className="spinner-container">
                <Spin size="large"/>
            </div>}
        </PrivateWrapper>
    )
}

export default Onboarding