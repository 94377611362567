import React from "react";
import { useNavigate } from "react-router";
import Button from "../Forms/Button/Button";

import ErrorImage from "../../Assests/Images/error.svg";

import { ROUTE_CONSTANTS } from "../../Routes/RouteConstants";

import StorageHandler from "../../Utils/StorageHandler";

import "./styles.scss";

type Props = {
  errorCode: string;
  loggedIn?: boolean;
};
const PageNotFound = ({ errorCode, loggedIn }: Props) => {
  const navigate = useNavigate();

  const redirectHandler = () => {
    if (loggedIn) {
      navigate(ROUTE_CONSTANTS.DASHBOARD);
    } else {
      StorageHandler.clear();
      navigate(ROUTE_CONSTANTS.HOME);
    }

    window.location.reload();
  };

  return (
    <div className="error-page">
      <img
        src={ErrorImage}
        alt="something went wrong"
        className="image-styles"
      />
      <div className="mx-2">
        <h1>{errorCode}</h1>
      </div>
      <p className="text-dark text-muted h5 fw-normal py-2">
        We are sorry, something went wrong. <br /> Please go back{" "}
        {loggedIn ? ` to the homepage` : ` and try again`}.
      </p>

      <Button id="back-to-home" onClick={() => redirectHandler()}>
        {loggedIn ? "Back to home" : "Go back"}
      </Button>
    </div>
  );
};

export default PageNotFound;
