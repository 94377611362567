import {CSSProperties} from "react";

type Props = {
    id?: string;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    children?: React.ReactNode;
    popupSize?: "lg";
    customClass?: string;
    innerCustomClass?: string;
    closeBtnCustomClass?: string;
    overlayCustomClass?: string;
    innerStyle?: CSSProperties;
    closeBtnStyle?: CSSProperties;
    overlayStyle?: CSSProperties;
    hasCustomHeader?: boolean;
    clickOutsideClose?: boolean;
};

const Popup = ({
                   isOpen,
                   setIsOpen,
                   id,
                   children,
                   customClass,
                   hasCustomHeader,
                   innerCustomClass,
                   innerStyle,
                   closeBtnStyle,
                   closeBtnCustomClass,
                   overlayStyle,
                   overlayCustomClass,
                   clickOutsideClose
               }: Props) => {
    return (
        <section
            className={[
                "popup",
                "panel panel-modal",
                isOpen ? "active" : "",
                customClass ? customClass : "",
            ].join(" ")}
            id={id}
        >
            <div
                style={innerStyle}
                className={[
                    "panel-modal-inner shadow",
                    innerCustomClass ? customClass : "",
                ].join(" ")}
            >
                {!hasCustomHeader && (
                    <div className="position-absolute top-0 end-0 p-3">
                        <button
                            className={[
                                "btn btn-close",
                                closeBtnCustomClass ? closeBtnCustomClass : "",
                            ].join(" ")}
                            onClick={e => setIsOpen(false)}
                            style={closeBtnStyle}
                        ></button>
                    </div>
                )}

                <article>{children}</article>
            </div>
            <div
                style={overlayStyle}
                className={[
                    "panel-overlay",
                    overlayCustomClass ? overlayCustomClass : "",
                ].join(" ")}
                onClick={e => clickOutsideClose ? setIsOpen(false) : null}
            ></div>
        </section>
    );
};

Popup.defaultProps = {
    hasCustomHeader: false,
    clickOutsideClose: true
};
export default Popup;
