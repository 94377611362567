import {Fragment} from "react";
import {Navigate, Outlet} from "react-router-dom";

import PrivateRoutes from "./PrivateRoute";

import TokenHandler from "../Utils/TokenHandler";
import {ROUTE_CONSTANTS} from "./RouteConstants";
import {ALL_ACCESS_URLS} from "../Constants/Values";

const PublicRoutes = () => {
    const hasToken = TokenHandler?.getToken();

    const checkIfUserIsLoggedIn = () => {
        if (hasToken) {
            return ALL_ACCESS_URLS.includes(window.location.pathname.split("/")[1]) ? <Outlet/> :
                <Navigate to={ROUTE_CONSTANTS.DASHBOARD} replace={false}/>
        }

        return <Fragment>
            {hasToken ? <PrivateRoutes/> : <Outlet/>}
        </Fragment>
    }

    return (<>{checkIfUserIsLoggedIn()}</>
    );
};

export default PublicRoutes;
