import MainWrapper from "../../Layouts/MainWrapper";

import {ReactComponent as NotFound} from "../../Assests/Images/404.svg";

import "./Styles.scss";

const ErrorNotFound = () => {
    return (
        <MainWrapper isHomepage={false} customTitle="Page not found">
            <div className="error-component">
                <NotFound className = "error-image"/>
                <button
                    className="bg-blue text-white font-sans text-sm font-semibold leading-6 rounded-xl px-10 py-2.5 rounded-full z-20"
                    onClick={() =>  window.location.href = "/"}
                >Back to Home Page</button>
            </div>
        </MainWrapper>
    )
}

export default ErrorNotFound;