import FactoryService from "../FactoryService";
import AssetsBaseService from "../AssetsBaseService";

import {ResultCountriesInterface} from "../../Models/Country";

const factoryService = new FactoryService();

class AssetsService {
    private GET_REGION_ENDPOINT: string = "region";

    async getRegion(): Promise<ResultCountriesInterface> {
        const response = await (
            await factoryService.create(AssetsBaseService)
        ).getAsJson(`${this.GET_REGION_ENDPOINT}`, false);

        return response;
    }

}

export default new AssetsService();
