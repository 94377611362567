import MainWrapper from "../../Layouts/MainWrapper";
import {TermsAndConditions as Terms} from "../../Components/TermsAndConditions";

const TermsAndConditionsPage = () => {
    return (
        <MainWrapper>
            <div className="mb-4 privacy-container container my-32 mx-24 pb-16">
                <Terms/>
            </div>
        </MainWrapper>
    );
}

export default TermsAndConditionsPage;