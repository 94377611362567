type Props = {
    name: string;
    id: string;
    value: string;
    options: Array<any>;
    disabled?: boolean;
    selectclass: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    ref?: (event: HTMLInputElement) => void;
    customRef?: any; // research react-hooks-form type for this field
    placeholder?: string;
    required?: boolean;
    error?: any;
    style?: any;
};

const Dropdown = ({
                      name,
                      id,
                      options,
                      disabled,
                      value,
                      selectclass,
                      onChange,
                      customRef,
                      ref,
                      placeholder,
                      required,
                      error,
    style
                  }: Props) => {
    return (
        <>
            <select
                id={id}
                name={name}
                disabled={disabled}
                {...(customRef && customRef)}
                {...(style && { style })}
                value={value}
                onChange={onChange}
                className={`${selectclass} ${error && 'border-danger'}`}
                required={!!required}
            >
                {placeholder && <option value="">{placeholder}</option>}
                {options.map((option, index) => (
                    <option key={`${option.value}_${index}`} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </select>
            <div className="text-error">{error}</div>
        </>
    );
};

export default Dropdown;
