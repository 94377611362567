import React, {useEffect, useState} from "react";
import {ReactI18NextChild} from "react-i18next";

import envConfig from "../../../Configs/env.config";

type Props = {
    testimonialSection: any;
}

const HappyCustomers = ({testimonialSection}: Props) => {

    return (
        <div className="container">
            <div className="mt-20 xl:mx-24 ">
                <div className="flex flex-col">
                    <h5 className="text-4xl text-light-black font-black font-sans mb-10">{
                        testimonialSection?.TestimonialTitle || ""
                    }</h5>
                    <h5 className="text-lg leading-loose font-sans font-normal text-gray-darker xl:mb-20 mb-14">{
                        testimonialSection?.TestimonialContent || ""
                    }</h5>
                </div>

                <div className="flex flex-row justify-between gap-6 xl:gap-16 overflow-x-scroll no-scrollbar">
                    {
                        testimonialSection?.testimonialsSection?.map((data: {
                            avatar: { id: any; };
                            fullname: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<ReactI18NextChild> | null | undefined;
                            testimonial: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<ReactI18NextChild> | null | undefined;
                        }, index: React.Key | null | undefined) => {
                            return (
                                <div key={index} className="xl:w-1/3 w-80 bg-white-darker rounded p-6 flex-shrink-0 xl:flex-1">
                                    <div className="flex items-center">
                                        <img src={`${envConfig.REACT_APP_DIRECTUS_BASE_URL}assets/${data.avatar.id}`}
                                             alt="avatar" className="w-14 h-14 rounded-full object-cover" width="56" height="56"/>
                                        <h5 className="text-lg font-sans font-bold dusty-black ml-4">{data.fullname}</h5>
                                    </div>
                                    <h5 className="text-lg font-sans leading-7 font-normal text-gray-darkest mt-4">{data.testimonial}</h5>
                                </div>
                            )
                        })
                    }
                </div>

            </div>
        </div>
    )
}

export default HappyCustomers;