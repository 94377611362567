import React from 'react';
import './InputStyle.scss';

interface Props {
    name: string;
    type: string;
    value?: string;
    placeholder?: string;
    withMargin?: boolean;
    labelType?: 'floating' | 'normal' | 'none';
    labelText?: string;
    labelClassName?: string;
    divClassName?: string;
    loading?: boolean;
    required?: boolean;
    disabled?: boolean;
    autoComplete?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    error?: any;
    id?: string;
    ref?: (event: HTMLInputElement) => void;
    min?: string;
    max?: string;
    textCenter?: boolean;
    maxLength?: number;
    rootProps?: React.InputHTMLAttributes<HTMLInputElement>;
    inputSize?: 'lg' | 'md' | 'sm' | 'xs';
    customRef?: any;
    readonly?: boolean;
    inputContainer?: boolean;
    defaultValue?: string;
    isHidden?: boolean;
    requiredMark?: boolean;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    inputClassName?: string;
    customStyle?: any;
}

const Input = ({
                   value,
                   name,
                   placeholder,
                   withMargin,
                   labelType = 'normal',
                   type,
                   labelText,
                   labelClassName,
                   divClassName,
                   loading,
                   required,

                   disabled,
                   autoComplete,
                   onChange,
                   customRef,
                   error,
                   id,
                   ref,
                   min,
                   max,
                   textCenter,
                   maxLength,
                   inputSize,
                   rootProps,
                   onKeyPress,
                   onKeyUp,
                   readonly,
                   defaultValue,
                   inputContainer = false,
                   isHidden,
                   requiredMark,
                   onBlur,
                   inputClassName,
                   customStyle
               }: Props) => {
    return (
        <>
            <div
                className={`${divClassName} ${
                    labelType === 'floating' ? 'form-floating' : ''
                } ${withMargin === true ? 'mb-3' : ''}`}
            >
                {labelType === 'normal' && (
                    <label
                        htmlFor={id}
                        className={['col-form-label', labelClassName].join(' ')}
                    >
                        {labelText}
                        {requiredMark && <span className="text-error"> *</span>}
                    </label>
                )}
                {!!inputContainer ? (
                    <div className="col-md-9">
                        <input
                            name={name}
                            hidden={isHidden}
                            type={type}
                            className={`form-control ${error ? 'border-danger' : ''} ${
                                inputSize && `form-control-${inputSize}`
                            } ${textCenter && 'text-center'} ${inputClassName}`}
                            id={id}
                            value={value}
                            defaultValue={defaultValue}
                            placeholder={placeholder}
                            onChange={onChange}
                            required={required}
                            disabled={loading || disabled}
                            autoComplete={autoComplete}
                            ref={(event) => event && ref && ref(event)}
                            {...(customRef && customRef)}
                            min={min}
                            max={max}
                            maxLength={maxLength}
                            {...rootProps}
                            {...(onKeyPress && {onKeyPress: onKeyPress})}
                            {...(onKeyUp && {onKeyUp: onKeyUp})}
                            readOnly={readonly}
                            onBlur={onBlur}
                            style={customStyle ? customStyle : {}}
                        />
                        {labelType === 'floating' && (
                            <label htmlFor={id} className={labelClassName}>
                                {labelText}
                            </label>
                        )}
                        <div className="text-error">{error}</div>
                    </div>
                ) : (
                    <>
                        <input
                            hidden={isHidden}
                            name={name}
                            type={type}
                            className={`form-control ${error ? 'border-danger' : ''} ${
                                inputSize && `form-control-${inputSize}`
                            } ${textCenter && 'text-center'} ${inputClassName}`}
                            id={id}
                            value={value}
                            defaultValue={defaultValue}
                            placeholder={placeholder}
                            onChange={onChange}
                            required={required}
                            disabled={loading || disabled}
                            autoComplete={autoComplete}
                            ref={(event) => event && ref && ref(event)}
                            {...(customRef && customRef)}
                            min={min}
                            max={max}
                            maxLength={maxLength}
                            {...rootProps}
                            {...(onKeyPress && {onKeyPress: onKeyPress})}
                            {...(onKeyUp && {onKeyUp: onKeyUp})}
                            readOnly={readonly}
                            onBlur={onBlur}
                            style={customStyle ? customStyle : {}}
                        />
                        {labelType === 'floating' && (
                            <label htmlFor={id} className={labelClassName}>
                                {labelText}
                            </label>
                        )}
                        <div className="text-error">{error}</div>
                    </>
                )}
            </div>
        </>
    );
};

export default Input;
