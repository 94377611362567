import { ReactComponent as LoadingAnimation } from '../../Assests/Icons/loading.svg';

import './styles.scss';
import {Spin} from "antd";
import React from "react";

const Loading = () => {
  return (
      <div className="xl:col-md-8 backdrop-blur-md fixed inset-0 z-50 flex items-center justify-center">
          <Spin size="large"/>
      </div>
  );
};

export default Loading;
