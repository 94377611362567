import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import parse from "html-react-parser";

import {ReactComponent as BallIcon} from "../../../Assests/Images/ball.svg";

import {getSectionContentByName} from "../../../Utils/getSectionContentByName";
import {useLoginPopUp} from "../../../Context/LoginContext";

const BottomSection = () => {
    const {t} = useTranslation();
    const [winningPotentialData, setWinningPotentialData] = useState<any>({
        getStartedTitle: "",
        getStartedContent: ""
    });
    const {isLoginPopupOpen, setIsLoginPopupOpen, setIsRegistered} = useLoginPopUp();

    const fetchWinningPotential = async () => {
        const cache = await caches.open("layoutContents");

        const response = await cache.match("layoutContents");

        if (response) {
            const data = await response.json();

            setWinningPotentialData({
                "getStartedTitle": data?.GetStartedSection?.translations[0].Title,
                "getStartedContent": data?.GetStartedSection?.translations[0].Content,
                "bottomSection": getSectionContentByName(data, "BottomSection"),
            })
        } else {
            let retryCounter = 0;

            const retryFetch = async () => {
                if (retryCounter < 1) {
                    retryCounter++;
                    fetchWinningPotential();
                }
            }

            retryFetch();
        }
    }

    useEffect(() => {
        fetchWinningPotential()
    }, []);

    return (
        <div className="container">                 
            <span className="bottomsection text-lg font-sans font-light leading-6 text-gray-darkest mb-6">
                {parse(winningPotentialData?.bottomSection || "")}
            </span>
        </div>
    )
}

export default BottomSection;