import FactoryService from '../FactoryService';
import ProfileBaseService from "../ProfileBaseService";
import AuthBaseService from "../AuthBaseService";

const factoryService = new FactoryService();

class ProfileService {
    private STATUS_ENDPOINT = 'api/Status';
    private PROFILE_ENDPOINT = 'api/Profile';
    private BLACKOUT_ENDPOINT = 'api/Status/blackout';
    private USERS_ENDPOINT = 'Users';

    async getStatus() {
        const result = await (
            await factoryService.create(ProfileBaseService)
        ).getAsJson(this.STATUS_ENDPOINT, true);

        if (result) {
            return result;
        }

        return null;
    }

    async setStatus(payload: any) {
        const result = await (
            await factoryService.create(ProfileBaseService)
        ).patchAsJson(this.STATUS_ENDPOINT, payload, true);

        if (result) {
            return result;
        }

        return null;
    }

    async getProfile() {
        const result = await (
            await factoryService.create(ProfileBaseService)
        ).getAsJson(this.PROFILE_ENDPOINT, true);

        if (result) {
            return result;
        }

        return null;
    }

    async getLanguageList() {
        const result = await (
            await factoryService.create(ProfileBaseService)
        ).getAsJson(`${this.PROFILE_ENDPOINT}/languages`, true);

        if (result) {
            return result;
        }

        return null;
    }

    async updateProfile(payload: any) {
        const result = await (
            await factoryService.create(ProfileBaseService)
        ).putAsJson(this.PROFILE_ENDPOINT, payload, true);

        if (result) {
            return result;
        }

        return null;
    }

    async getEmailOtp(email: string) {
        const payload = {
            email: email
        }
        const result = await (
            await factoryService.create(AuthBaseService)
        ).patchAsJson(this.USERS_ENDPOINT, payload, true);

        if (result) {
            return result;
        }

        return null;
    }

    async verifyEmail(otp: string) {
        const payload = {
            code: otp
        }

        const result = await (
            await factoryService.create(AuthBaseService)
        ).postAsJson(`${this.USERS_ENDPOINT}/email/verification`, payload, true);

        if (result) {
            return result;
        }

        return null;
    }
}


export default new ProfileService();
