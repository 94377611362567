export const ROUTE_CONSTANTS = {
    HOME: "/home",
    REGISTER: "/",
    DASHBOARD: "/dashboard",
    COOKIE: "/cookie",
    TRANSACTION: "/transactions",
    SETTINGS: "/settings",
    ONBOARDING: "/onboarding",
    TELEGRAM: "/telegram",
    ERROR_PAGE: "/not-found",
    PRIVACY_POLICY: "/privacy-policy",
    BLOG: "/blog",
    TOP_UP: "/topup",
    CUSTOM_HEADER: "/custom-header",
    SIGN_UP: "/register",
    PROFILE: "/profile",
    PRODUCTS: "/products",
    NOT_FOUND: "/not-found",
    PAGE_NOT_FOUND: "/404-not-found",
    TERMS_AND_CONDITIONS: "/terms-and-conditions",
};
