import jwt_decode, {JwtPayload} from "jwt-decode";
import Storage from "./StorageHandler";
import {redirect} from "react-router-dom";

interface TokenInterface {
    getToken: () => string;
    getRefreshToken: () => string;
    setToken: (token: string) => void;
    setRefreshToken: (token: string) => void;
    getTokenObject: () => JwtPayload;
    clearAllTokens: () => void;
}

class TokenHandler implements TokenInterface {
    getToken(): string {
        return Storage.get("token") ?? "";
    }

    getRefreshToken(): string {
        return Storage.get("refreshToken") ?? "";
    }

    getDecodedToken(): any {
        const token = Storage.get("token");
        if (token) {
            let isValidToken = this.validateToken(token);
            if (isValidToken) {
                return jwt_decode<JwtPayload>(token);
            }
        }

        return null;
    }

    clearAllTokens() {
        Storage.delete("token");
        Storage.clear();
    }

    getTokenObject() {
        const token = Storage.get("token");
        if (token) {
            let isValidToken = this.validateToken(token);
            if (isValidToken) {
                return JSON.parse(token);
            }
        }
        Storage.clear();
    }

    getExternalRef(): any {
        const token = Storage.get("token");
        if (token) {
            const userRef: any = jwt_decode<JwtPayload>(token);
            return userRef.UserExternalReference;
        }
        return null;
    }

    setToken(token: string) {
        const isValidToken = this.validateToken(token);
        if (isValidToken) {
            Storage.saveToLocalStorage("token", token);
        }
    }

    setRefreshToken(token: string) {
        Storage.saveToLocalStorage("refreshToken", token);
    }

    private validateToken(token: string): boolean {
        try {
            let decodedToken = jwt_decode<JwtPayload>(token);
            let dateNow = new Date();
            const expToken = Number(decodedToken.exp) * 1000;
            const currentTime = dateNow.getTime();
            let result = expToken > currentTime;
            return result;
        } catch {
            Storage.clear();
            return false;
        }
    }
}

export default new TokenHandler();
