import {ReactComponent as WhiteLogo} from "../../../Assests/Images/logo_white.svg";
import {ROUTE_CONSTANTS} from "../../../Routes/RouteConstants";
import TermsOfService from "../../../Pages/Terms";

type Props = {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode;
    setTermOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsLoginPopupOpen?: any;
};

const OffCanvas = ({children, isOpen, setIsOpen, setTermOpen, setIsLoginPopupOpen}: Props) => {
    return (<div>
            <div
                className={`fixed inset-0 z-[100] transition-opacity duration-300 ${isOpen ? "opacity-100" : "opacity-0"} ${isOpen ? "pointer-events-auto" : "pointer-events-none"}`}
                onClick={() => setIsOpen(false)}
            >
                <div
                    className={`fixed inset-y-0 left-0 bg-[#326E88] opacity-90 text-white w-80 transition-transform duration-300 transform ${isOpen ? "translate-x-0" : "-translate-x-full"}`}
                >
                    <div className="flex justify-start pt-16 ps-8">
                        <WhiteLogo/>
                    </div>
                    <nav className="flex flex-col justify-start space-y-12 ps-8 pt-24">
                        {
                            children
                        }
                    </nav>

                    <div className="fixed bottom-0 left-0 w-64 bg-[#326E88] flex px-8 py-2">
                        <div className="flex flex-col gap-6 mb-10">
                            <a
                                href={ROUTE_CONSTANTS.PRIVACY_POLICY}
                                className="text-sm font-sans font-normal text-white flex mb-1.5"
                            >Privacy Policy</a>
                            <a
                                href="#"
                                onClick={() => setTermOpen(true)}
                                className="text-sm font-sans font-normal text-white flex mb-12"
                            >Terms & Conditions</a>

                            <button
                                onClick={() => setIsLoginPopupOpen && setIsLoginPopupOpen(true)}
                                className="text-base font-sans font-medium text-white bg-[#20A0E1] w-64 py-3.5 px-4 rounded-full">Login
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    );
}

export default OffCanvas;