import {useEffect, useState} from "react";
import {MenuOutlined} from "@ant-design/icons";

import useMatchMedia from "../../../Hooks/useMatchMedia";

import {ReactComponent as LogoIcon} from "../../../Assests/Images/logo.svg";

import {MOBILE_MAX_WIDTH_QUERY} from "../../../Constants/Values";
import {gtagEventHandler} from "../../../Utils/gtagEventHandler";

import "./Header.scss";

type HeaderProps = {
    isHomepage: boolean
    customTitle?: string
    setLoginPopupOpen: any
    setIsOffCanvas: React.Dispatch<React.SetStateAction<boolean>>
};

const Header = ({isHomepage, customTitle, setLoginPopupOpen, setIsOffCanvas}: HeaderProps) => {
    const {match: isMobileDevice} = useMatchMedia(MOBILE_MAX_WIDTH_QUERY);
    const [headerLinks, setHeaderLinks] = useState<any>(null);

    const fetchHeader = async () => {
        const cache = await caches.open("layoutContents");

        const response = await cache.match("layoutContents");

        if (response) {
            const data = await response.json();

            setHeaderLinks(data?.topmenu || null);
        } else {
            let retryCounter = 0;

            const retryFetch = async () => {
                if (retryCounter < 1) {
                    retryCounter++;
                    fetchHeader();
                }
            }

            retryFetch();
        }

    }

    useEffect(() => {
        fetchHeader();
    }, []);

    useEffect(() => {
        let prevScrollpos = window.pageYOffset;
        const headerElement = document.getElementById("mainNav");

        const scrollHandler = () => {
            const currentScrollPos = window.pageYOffset;

            if (currentScrollPos > 300) {
                headerElement?.classList.add("is-fixed");
                headerElement?.classList.remove("is-visible");
            }

            if (prevScrollpos > currentScrollPos && currentScrollPos > 300) {
                headerElement?.classList.add("is-visible");
            }

            if (prevScrollpos > currentScrollPos && currentScrollPos === 0) {
                headerElement?.classList.remove("is-fixed");
                headerElement?.classList.remove("is-visible");
            }

            prevScrollpos = currentScrollPos;
        };

        if (isMobileDevice) {
            headerElement?.classList.add("is-fixed");
            headerElement?.classList.add("is-visible");
        } else {
            window.addEventListener("scroll", scrollHandler);
        }

    }, [isMobileDevice]);

    const registrationToggleHandler = () => {

        setLoginPopupOpen(true);

        gtagEventHandler({
            event_category: "Login",
            event_label: "Header Login Button",
        }, "login_button_clicked");
    }

    return (
        <header id="mainNav" className="fixed w-full z-50 bg-white">
            <div className="container mx-auto flex justify-between items-center py-4">
                <div className="flex items-center justify-center">
                    {isMobileDevice && <MenuOutlined rev={undefined} className="me-3"
                                                     onClick={() => setIsOffCanvas(true)}
                    />}

                    <LogoIcon
                        className="cursor-pointer"
                        onClick={() => window.location.href = "/"}
                    />
                    <nav className="hidden md:flex space-x-20 ms-28">
                        {
                            headerLinks?.map((link: any, index: number) => {
                                return (
                                    <a key={index} href={link.translations[0].slug}
                                       className="text-base font-medium text-primary-dark">{link.translations[0].name}</a>
                                )
                            })
                        }
                    </nav>
                </div>
                <div className="flex items-center">
                    <button
                        onClick={registrationToggleHandler}
                        className="rounded-full text-base border-1 border-primary-dark text-primary-dark px-10 py-2 font-medium"
                    >
                        Login
                    </button>
                </div>
            </div>
        </header>
    );
};

Header.defaultProps = {
    isHomepage: true,
};

export default Header;
