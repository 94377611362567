import React, { createContext, useContext, useState } from 'react';

interface ProviderProps {
    children: JSX.Element | JSX.Element[];
}

type LoginContextType = {
    isLoginPopupOpen: boolean;
    setIsLoginPopupOpen: (value: boolean) => void;
    isRegistered: boolean;
    setIsRegistered: (value: boolean) => void;
};

const LoginContext = createContext<LoginContextType | undefined>(undefined);

export const LoginPopUpProvider = ({ children }: ProviderProps) => {
    const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);

    return (
        <LoginContext.Provider value={{ isLoginPopupOpen, setIsLoginPopupOpen, isRegistered,  setIsRegistered}}>
            {children}
        </LoginContext.Provider>
    );
};

export const useLoginPopUp = () => {
    const context = useContext(LoginContext);
    if (context === undefined) {
        throw new Error('useLoginPopUp must be used within a LoginPopUpProvider');
    }
    return context;
};