import React, {useEffect, useRef, useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input';

import TermsOfService from '../../Pages/Terms';
import CustomPopup from '../CustomPopup/CustomPopup';

import {EMAIL_PATTERN, TOAST_BOTTOM_CENTER, TOAST_DURATION} from '../../Constants/Values';
import Toast from '../../Utils/ToastHandler';

import AssetsService from '../../Services/Assets/AssetsService';
import './Styles.scss';
import {gtagEventHandler} from "../../Utils/gtagEventHandler";
import {hidePhoneNumber} from "../../Utils/hidePhoneNumber";
import {toggleFreshChatWidget} from "../../Utils/toggleFreshChatWidget";

type FormData = {
    phone: string;
    email: string;
};

type Props = {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    registerPhoneHandler: (arg1: string, arg2: string) => void;
    phoneVerificationHandler: (arg1: string) => void;
    isRegistered: boolean;
    setIsRegistered: (value: boolean) => void;
};
const LoginRegisterPopup = ({
                                isOpen,
                                setIsOpen,
                                registerPhoneHandler,
                                phoneVerificationHandler,
                                loading,
                                setIsRegistered,
                                isRegistered,
                            }: Props) => {
    const phoneInputRef = useRef<any>(null);
    const {t} = useTranslation();

    const {
        control,
        register,
        handleSubmit,
        formState: {errors, isValid, isDirty},
    } = useForm<any>({
        mode: 'onChange',
    });

    const [termsAndConditions, setTermsAndConditions] = useState({
        terms: false,
        credit: false,
    });
    const [isTermsOpened, setIsTermsOpened] = useState(false);

    const [failedTerms, setFailedTerms] = useState(false);
    const [failedCredit, setFailedCredit] = useState(false);
    const [region, setRegion] = useState<any>(null);
    const [phoneStep, setPhoneStep] = useState(1);

    const getRegionHandler = () => {
        AssetsService.getRegion()
            .then((res: any) => {
                setRegion(res?.region);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const submitForm: SubmitHandler<FormData> = (data: FormData) => {
        if (isRegistered) {
            if (phoneStep === 1) {
                gtagEventHandler(
                    {
                        event_category: 'Registration',
                        event_category_description: "Phone Popup - Step 1",
                        value: 'phone registration - ' + hidePhoneNumber(data.phone),
                        mobile: data.phone,
                        event_label: 'Phone Registration - Step 1',
                    },
                    'phone_registration_step_1');

                setPhoneStep(2);
                return;
            }

            setFailedTerms(!termsAndConditions.terms);
            setFailedCredit(!termsAndConditions.credit);
            if (!termsAndConditions.terms || !termsAndConditions.credit) {
                Toast.error(
                    t('Error-Accept-Terms-And-Credits'),
                    TOAST_DURATION,
                    TOAST_BOTTOM_CENTER
                );
                return;
            }

            gtagEventHandler(
                {
                    event_category: 'Registration',
                    event_category_description: "Phone Popup - Step 2",
                    value: 'phone registration - ' + hidePhoneNumber(data.phone) + " - " + data.email,
                    mobile: data.phone,
                    email: data.email,
                    event_label: 'Phone Registration - Step 2',
                },
                'phone_registration_step_2'
            );

            registerPhoneHandler(data.phone, data.email);
            return;
        } else {
            gtagEventHandler(
                {
                    event_category: 'Login',
                    event_category_description: "Phone Popup",
                    value: 'phone login - ' + hidePhoneNumber(data.phone),
                    mobile: data.phone,
                    event_label: 'Phone Login',
                },
                'phone_login'
            );
            phoneVerificationHandler(data.phone);
            return;
        }
    };

    useEffect(() => {
        getRegionHandler();
    }, []);

    return (
        <>
            <CustomPopup isOpen={isOpen} setIsOpen={setIsOpen}>
                <form
                    onSubmit={(e) => e.preventDefault()}
                    id={isRegistered ? 'register-form' : 'login-form'}
                >
                    <p className="font-sans text-lg xl:text-xl text-gray-darkest mb-6">
                        {isRegistered ? 'Register' : 'Login'}
                    </p>

                    {isRegistered && (phoneStep === 1 ?
                        <span
                            className="text-gray-dark font-sans font-normal text-sm">{t("Registration-Step-One-Title")}</span> :
                        <span
                            className="text-gray-dark font-sans font-normal text-sm">{t("Registration-Step-Two-Title")}</span>)}

                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <Controller
                            control={control}
                            name="phone"
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Phone is required',
                                },
                                validate: isPossiblePhoneNumber,
                            }}
                            render={({field: {onChange, value}}) => {
                                return (
                                    <div className="mt-2">
                                        {
                                            phoneStep === 1 ? <div
                                                className="flex justify-between items-center w-full bg-gray-dark rounded-full hide-focus">
                                                <PhoneInput
                                                    ref={phoneInputRef}
                                                    addInternationalOption={false}
                                                    value={value}
                                                    onChange={onChange}
                                                    defaultCountry={region ? region : 'MT'}
                                                    id={
                                                        isRegistered
                                                            ? 'register-phone-input'
                                                            : 'login-phone-input'
                                                    }
                                                    placeholder={'Phone Number'}
                                                    className="w-56 xl:w-72 rounded-full p-3 bg-transparent"
                                                    limitMaxLength
                                                />
                                                <button
                                                    type="submit"
                                                    id={
                                                        isRegistered
                                                            ? 'register-form-button'
                                                            : 'login-form-button'
                                                    }
                                                    disabled={!isDirty || !isValid}
                                                    onClick={handleSubmit(submitForm)}
                                                    className="bg-blue text-white font-sans text-sm font-semibold rounded-xl px-9 py-3 m-1.5 rounded-full"
                                                >
                                                    <span className="text-sm font-sans font-medium text-white">
                                                    {loading
                                                        ? 'Loading '
                                                        : isRegistered
                                                            ? 'Next '
                                                            : 'Login '}
                                                    </span>
                                                </button>
                                            </div> : <div
                                                className="flex justify-between items-center w-full bg-gray-dark rounded-full hide-focus">
                                                <input
                                                    placeholder={"Enter Email"}
                                                    type="email"
                                                    className="w-56 xl:w-72 rounded-full p-3 bg-transparent"
                                                    {...register("email", {
                                                        required: {
                                                            value: true,
                                                            message: "Email is required",
                                                        },
                                                        pattern: {
                                                            value: EMAIL_PATTERN,
                                                            message: "Invalid email address",
                                                        },
                                                    })}
                                                />
                                                <button
                                                    type="submit"
                                                    id={
                                                        isRegistered
                                                            ? 'register-form-button'
                                                            : 'login-form-button'
                                                    }
                                                    disabled={!isDirty || !isValid}
                                                    onClick={handleSubmit(submitForm)}
                                                    className="bg-blue text-white font-sans text-sm font-semibold rounded-xl px-9 py-3 m-1.5 rounded-full"
                                                >
                                                    <span className="text-sm font-sans font-medium text-white">
                                                    {loading
                                                        ? 'Loading '
                                                        : isRegistered
                                                            ? 'Register '
                                                            : 'Login '}
                                                    </span>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                );
                            }}
                        />

                        {errors.phone && (
                            <div className="text-error " id="phone-validation-error">
                                {errors['phone'].message + ''}
                            </div>
                        )}
                        {errors.phone?.type === 'validate' && (
                            <div className="text-error " id="phone-invalid-error">
                                Invalid phone number
                            </div>
                        )}
                        {errors.email && (
                            <div className="text-error " id="email-validation-error">
                                {errors['email'].message + ''}
                            </div>
                        )}

                        <div
                            className={`login-registration-question 
                            ${
                                !isRegistered ? 'mb-5 mt-6' : phoneStep === 1 ? 'mt-6 mb-5' : "mt-2"
                            }
                            `}
                        >
                            <div
                                className="flex flex-col"
                            >
                                <span
                                    className="text-blue font-sans font-normal text-sm"
                                    onClick={() => {
                                        setIsRegistered(!isRegistered);
                                    }}
                                >
                            {isRegistered
                                ? 'Already registered?'
                                : 'Not registered?'}{' '}
                                    Start Here
                            </span>
                                <span
                                    className="text-blue font-sans font-normal text-sm mt-2"
                                    onClick={() => toggleFreshChatWidget()}
                                >
                            Contact Support
                            </span>
                            </div>

                        </div>


                        {isRegistered && phoneStep === 2 ? (
                            <div className="login-register-terms-and-credit gap-2">
                                <div className="flex items-center mt-2 xl:me-3">
                                    <div className="flex items-center terms-conditions-checkbox">
                                        <input
                                            type="checkbox"
                                            id="terms-checkbox"
                                            className="me-2"
                                            value="option2"
                                            onClick={() => {
                                                setTermsAndConditions({
                                                    ...termsAndConditions,
                                                    terms: !termsAndConditions.terms,
                                                });
                                            }}
                                        />
                                        <label htmlFor="terms-checkbox"
                                               className={`text-gray-dark font-sans font-normal text-sm`}>
                                            Accept <small
                                            className="font-sans font-normal text-sm text-[#20A0E1] underline cursor-pointer"
                                            onClick={() => setIsTermsOpened(!isTermsOpened)}>Terms & Conditions</small>
                                        </label>
                                    </div>
                                </div>

                                <div className="flex items-center mt-2">
                                    <div className="flex items-center terms-conditions-checkbox">
                                        <input
                                            type="checkbox"
                                            id="credit-checkbox"
                                            className="me-2"
                                            value="option2"
                                            onClick={() => {
                                                setTermsAndConditions({
                                                    ...termsAndConditions,
                                                    credit: !termsAndConditions.credit,
                                                });
                                            }}
                                        />
                                        <label htmlFor="credit-checkbox"
                                               className={`text-gray-dark font-sans font-normal text-sm`}>
                                            Receive Messages and Free Credits
                                        </label>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>

                    <TermsOfService
                        id="termsOfService"
                        isOpened={isTermsOpened}
                        setIsOpened={setIsTermsOpened}
                    />
                </form>
            </CustomPopup>
        </>
    );
};

export default LoginRegisterPopup;
