import envConfig from "../Configs/env.config";
import BaseService from "./BaseService";
import IServiceProvider from "./IServiceProvider";

class AssetsBaseService extends BaseService implements IServiceProvider {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {
		super();
	}

	async initialize() {
		this.baseUrl = envConfig.REACT_APP_ASSETS_BASE_URL;
	}
}

export default AssetsBaseService;
