import {useState} from "react";

import {ReactComponent as PlusIcon} from "../../Assests/Icons/plus.svg";
import {ReactComponent as CloseIcon} from "../../Assests/Icons/AccordionClose.svg";

import "./Styles.scss"

type AccordionProps = {
    title: string
    content: string
}
const Accordion = ({title, content}: AccordionProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const toggleAccordion = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className="faq-accordion">
            <div className="faq-accordion-title cursor-pointer" onClick={() => toggleAccordion()}>
                <span className="font-sans text-lg leading-normal font-medium text-dusty-black">{title}</span>
                {isOpen ? <CloseIcon/> :
                    <PlusIcon/>}
            </div>

            <div className={`${isOpen ? "my-3 text-gray-darker text-sm font-medium leading-6" : "d-none"}`}>
                <span>{content}</span>
            </div>
        </div>
    )
}

export default Accordion