import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getRoutePages} from "../../Services/Blog/BlogService";
import Loading from "../../Components/Loading";
import MainWrapper from "../../Layouts/MainWrapper";
import {ROUTE_CONSTANTS} from "../../Routes/RouteConstants";
import parse from "html-react-parser";

const RoutePages = () => {
    const {pageRoute} = useParams();
    const navigate = useNavigate();

    const [pageDetails, setPageDetails] = useState<any>("");
    const [loading, setLoading] = useState<boolean>(false);

    const pageRouteHandler = async (routeName: string) => {
        setLoading(true);

        await getRoutePages(routeName, "en-US").then((res) => {
            const pageDetails = res.route_pages_translations[0]?.content;

            if (!pageDetails) {
                navigate(ROUTE_CONSTANTS.NOT_FOUND)
            }

            console.log(pageDetails);

            setPageDetails(pageDetails);
            setLoading(false);

        }).catch((err) => {
            console.log(err);
            setLoading(false);
        });
    }

    useEffect(() => {

        if (pageRoute) {
            pageRouteHandler(pageRoute);
        }
    }, [pageRoute]);

    if (loading) {
        return <Loading/>
    }

    return (
        <MainWrapper isHomepage={false}>
            <div className="container mt-32">
                <h5 className="text-lg font-sans font-normal leading-6 text-gray-darkest mb-6">
                    {parse(pageDetails || "")}
                </h5>
            </div>
        </MainWrapper>
    )
}

export default RoutePages;