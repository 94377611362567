import FactoryService from '../FactoryService';

import ReportingBaseService from "../ReportingBaseService";

const factoryService = new FactoryService();

class ReportingService {
    private HISTORY_ENDPOINT = 'api/Reporting/tip/history';

    async getReporting(searchTerm: string | null, pageNumber:number) {
        let queryParam = '?';
        if (searchTerm !== null && searchTerm !== '') {
            queryParam += `Search=${searchTerm}&`;
        }

        const result = await (
            await factoryService.create(ReportingBaseService)
        ).getAsJson(`${this.HISTORY_ENDPOINT}${queryParam}PageNumber=${pageNumber}`, true);

        if (result) {
            return result;
        }

        return null;
    }

}

export default new ReportingService();
