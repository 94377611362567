import {Navigate, Route, Routes} from "react-router-dom";
import PrivateRoutes from "./PrivateRoute";
import PublicRoutes from "./PublicRoutes";
import PageNotFound from "../Components/Error";

import {ROUTE_CONSTANTS} from "./RouteConstants";

import TokenHandler from "../Utils/TokenHandler";
import Home from "../Pages/Home/Home";
import Dashboard from "../Pages/Dashboard/Dashboard";
import CookieConsent from "../Pages/CookieConsent/CookieConsent";
import Onboarding from "../Pages/Onboarding/Onboarding";
import TelegramSteps from "../Pages/Home/TelegramSteps/TelegramSteps";
import ErrorNotFound from "../Pages/Error/ErrorNotFound";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import TopUp from "../Pages/TopUp/TopUp";
import Register from "../Pages/Register/Regsiter";
import Blog from "../Pages/Blog/Blog";
import Products from "../Pages/Products/Products";
import RoutePages from "../Pages/RoutePages/RoutePages";
import TermsAndConditionsPage from "../Pages/Terms/TermsAndConditionsPage";

type RequireAuthProps = {
    children: any;
    redirectTo: string;
};
const Router = () => {
    const IsLoggedIn = ({children, redirectTo}: RequireAuthProps) => {
        const hasToken = TokenHandler?.getToken();
        const externalReference = TokenHandler?.getDecodedToken()?.UserExternalReference;
        return hasToken && !externalReference ? children : <Navigate to={redirectTo} replace={true}/>;
    };

    return (
        <Routes>
            <Route
                path={ROUTE_CONSTANTS.ONBOARDING}
                element={
                    <IsLoggedIn redirectTo={ROUTE_CONSTANTS.HOME}>
                        <Onboarding/>
                    </IsLoggedIn>
                }
            />

            <Route element={<PublicRoutes/>}>
                <Route path={ROUTE_CONSTANTS.REGISTER} element={<Home/>}/>
                <Route path={ROUTE_CONSTANTS.HOME} element={<Home/>}/>
                <Route path={ROUTE_CONSTANTS.COOKIE} element={<CookieConsent/>}/>
                <Route path={ROUTE_CONSTANTS.TELEGRAM} element={<TelegramSteps/>}/>
                <Route path={ROUTE_CONSTANTS.PRIVACY_POLICY} element={<PrivacyPolicy/>}/>
                <Route path={ROUTE_CONSTANTS.SIGN_UP} element={<Register/>}/>
                <Route path={ROUTE_CONSTANTS.BLOG} element={<Blog/>}/>
                <Route path={`${ROUTE_CONSTANTS.BLOG}/:blogContext?`} element={<Blog/>}/>
                <Route path={`${ROUTE_CONSTANTS.REGISTER}/:pageRoute`} element={<RoutePages/>}/>
                <Route path={ROUTE_CONSTANTS.PRODUCTS} element={<Products/>}/>
                <Route path={ROUTE_CONSTANTS.NOT_FOUND} element={<ErrorNotFound/>}/>
                <Route path={ROUTE_CONSTANTS.TERMS_AND_CONDITIONS} element={<TermsAndConditionsPage/>}/>
                <Route path={ROUTE_CONSTANTS.PAGE_NOT_FOUND} element={<ErrorNotFound/>}/>
                <Route
                    path="*"
                    element={<ErrorNotFound/>}
                />
            </Route>

            <Route element={<PrivateRoutes/>}>
                <Route path={ROUTE_CONSTANTS.DASHBOARD} element={<Dashboard/>}/>
                <Route path={ROUTE_CONSTANTS.TOP_UP} element={<TopUp/>}/>
                <Route path={ROUTE_CONSTANTS.TRANSACTION} element={<Dashboard/>}/>
                <Route path={"/"} element={<Dashboard/>}/>
                <Route
                    path="*"
                    element={<PageNotFound errorCode="404" loggedIn={true}/>}
                />
            </Route>

        </Routes>
    );
};

export default Router;
