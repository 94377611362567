import "bootstrap/dist/css/bootstrap.css";

import {createRoot} from "react-dom/client";
import "swiper/css/bundle";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./styles/index.scss";
import "./styles/inPlayStyles.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import TagManager from "react-gtm-module";
import envConfig from "./Configs/env.config";

const tagManagerArgs = {
    gtmId: envConfig.REACT_APP_GTM
}

TagManager.initialize(tagManagerArgs)


const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(<App/>);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
