import {Navigate, Outlet} from "react-router-dom";

import {useLogoutSession} from "../Hooks/useLogoutSession";

import PublicRoutes from "./PublicRoutes";
import {ROUTE_CONSTANTS} from "./RouteConstants";

import TokenHandler from "../Utils/TokenHandler";
import PrivateWrapper from "../Layouts/PrivateWrapper";
import {ALL_ACCESS_URLS} from "../Constants/Values";

const PrivateRoutes = () => {
    const logoutSession = useLogoutSession(false);
    const hasToken = TokenHandler?.getToken();
    const externalReference = TokenHandler?.getExternalRef();

    const onboardingHandler = () => {
        if (hasToken && externalReference) {
            if (ALL_ACCESS_URLS.includes(window.location.pathname.split("/")[1])) {
                return <Outlet/>;
            }

            return <PrivateWrapper>
                <Outlet/>
            </PrivateWrapper>
        } else if (hasToken && !externalReference) {
            return <Navigate to={ROUTE_CONSTANTS.ONBOARDING} replace={false}/>;
        } else {
            logoutSession();
            return <PublicRoutes/>;
        }
    }

    const preventGoingBackToDashboardIfUserIsNotLoggedIn = () => {
        if (!hasToken) {
            const urlParams = new URLSearchParams(window.location.search);
            const session = urlParams.get("session");

            if (session) {
                logoutSession();
                localStorage.setItem("session", session);
                return <Navigate to={`${ROUTE_CONSTANTS.REGISTER}?action=transaction`} replace={true}/>;
            } else {
                logoutSession();
                return <Navigate to={`${ROUTE_CONSTANTS.REGISTER}?action=login`} replace={true}/>;
            }
        }
        return onboardingHandler();
    };

    return preventGoingBackToDashboardIfUserIsNotLoggedIn();
};

export default PrivateRoutes;
