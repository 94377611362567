import {useNavigate} from "react-router-dom";

import {useAppData} from "../Context/AppContext";

import {ROUTE_CONSTANTS} from "../Routes/RouteConstants";


import StorageHandler from "../Utils/StorageHandler";

export const useLogoutSession = (resetChatButton: boolean = false) => {
    const {setUserAccount, userAccountDetails} = useAppData();
    const navigate = useNavigate();

    const storageHandler = () => {
        StorageHandler.clear();
        setUserAccount({
            ...userAccountDetails,
            email: "",
        });

        if (resetChatButton) {
            // @ts-ignore
            window?.fcWidget?.setConfig({
                headerProperty: {
                    hideChatButton: false,
                },
            });

            // @ts-ignore
            window.fcWidget.user.clear();
        }

        navigate(ROUTE_CONSTANTS.HOME);
    };

    return () => {
        storageHandler();
    };
};
