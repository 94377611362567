import React from "react";
import {Spin} from "antd";

import "./Styles.scss";

const LoggingInLoader = () => {
    return <div className="logging-in-container">
        <div className="logging-in-inner">
            <h3 className="mb-2">In Play Tips</h3>
            <div className="logging-in-spinner">
                <Spin/>
                <span className="ps-3">Logging you in...</span>
            </div>
        </div>
    </div>
}

export default LoggingInLoader;