import MainWrapper from "../../Layouts/MainWrapper";
import {ReactComponent as GraphIcon} from "../../Assests/Icons/growth-chart.svg";
import {ReactComponent as ProductPieIcon} from "../../Assests/Images/product-pie.svg";

import {useTranslation} from "react-i18next";
import WinningPotential from "../../Components/Sections/WinningPotential/WinningPotential";
import React, {useEffect, useState} from "react";
import {getProductContent} from "../../Services/Blog/BlogService";
import Loading from "../../Components/Loading";
import parse from "html-react-parser";
import "./Styles.scss";

const Products = () => {
    const {t} = useTranslation();
    const [productData, setProductData] = useState<any>(null);
    const [loading, setLoading] = useState(false)

    const fetchProductContent = async () => {
        setLoading(true);
        await getProductContent().then((res) => {
            const productDetail = res?.products_page?.translations[0];
            const metaTitle = productDetail?.meta_title || res?.products_page?.translations[0].title || "";
            const metaDescription = productDetail?.meta_description;

            document.title = metaTitle || "";
            document.querySelector('meta[property="title"]')?.setAttribute("content", metaTitle || "");
            document.querySelector('meta[name="description"]')?.setAttribute("content", metaDescription || "");

            setProductData(productDetail);
            setLoading(false);
        }).catch((error => {
            setLoading(false);
            console.log(error)
        }))
    }

    useEffect(() => {
        fetchProductContent();

        window.scrollTo(0, 0);
    }, []);
    return (<MainWrapper>

            <div className="xl:container xl:mx-auto mt-32">
                <div className="flex items-center justify-center">
                    <h5 className="bg-light-green text-dark-green text-xl tracking-wide font-sans font-normal leading-6 px-6 py-2 rounded-md">{productData?.title}</h5>
                </div>

                <div className="bg-lighter-green w-full my-7 xl:hidden">
                    <div className="flex flex-col items-center justify-center me-6 px-16 py-8">
                        <GraphIcon className="mb-6"/>
                        <h5 className="text-4xl font-black font-sans text-gray-darkest text-center leading-normal">{t('product-consistent-win')}</h5>
                        <h5 className="text-lg font-sans font-thin text-gray-darkest">{t('product-consistent-win-description')}</h5>
                    </div>
                </div>

                <div className="xl:mt-16 rounded-4xl bg-none xl:bg-lighter-green">
                    <div className="flex flex-col md:flex-row px-7 xl:px-20 xl:py-6">

                        <div className="hidden xl:block w-1/4">
                            <div className="flex flex-col me-6">
                                <GraphIcon className="mb-6"/>
                                <h5 className="text-4xl font-black font-sans text-light-black leading-normal">{t('product-consistent-win')}</h5>
                                <h5 className="text-lg font-sans font-thin text-light-black">{t('product-consistent-win-description')}</h5>
                            </div>
                        </div>

                        <div className="full xl:w-3/4">
                            <div className="flex flex-col">
                                <div className="mt-1">
                                    <h5 className="text-2xl font-sans font-bold leading-7 text-gray-darkest">{productData?.green_title}</h5>
                                    <h5 className="text-2xl font-sans font-bold leading-7 text-gray-darkest mb-6">{productData?.green_subtitle}</h5>
                                </div>
                                <span
                                    className="product text-lg font-sans font-light leading-6 text-gray-darkest mb-2 xl:mb-6">
                                    {parse(productData?.green_content || "")}
                                </span>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="mt-2 xl:mt-4 px-7 xl:px-0">
                    <div className="flex flex-col md:flex-row xl:px-20 py-6">
                        <div className="hidden xl:block w-1/4"></div>
                        <div className="full xl:w-3/4">
                            <div className="flex flex-col">
                                <h5 className="text-lg font-sans font-bold leading-6 text-gray-darkest mb-6">
                                    {productData?.white_title}
                                </h5>
                                <span className="product text-lg font-sans font-light leading-6 text-gray-darkest mb-6">
                                    {parse(productData?.white_content || "")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="xl:rounded-4xl bg-lighter-green mt-20 mb-4 xl:mt-12 xl:mb-12">
                    <div className="flex flex-col xl:items-center md:flex-row xl:px-12 pt-24 pb-6 xl:pb-24">
                        <div className="w-1/4">
                            <div className="flex flex-col me-6 relative ps-4 xl:ps-0">
                                <ProductPieIcon className="absolute -top-52 scale-90 w-96 xl:w-auto"/>
                            </div>
                        </div>

                        <div className="w-full xl:w-3/4 pt-16 xl:pt-0">
                            <div className="flex flex-col">
                                <h5 className="text-5xl xl:text-4xl ps-8 xl:ps-6 font-sans font-black leading-normal xl:leading-7 text-light-black">
                                    {productData?.pie_title}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-3 xl:mt-4">
                    <div className="flex flex-col md:flex-row px-7 xl:px-20 py-6">
                        <div className="hidden xl:block w-1/4"></div>
                        <div className="full xl:w-3/4">
                            <div className="flex flex-col">
                                <span className="product text-lg font-sans font-light leading-6 text-gray-darkest mb-6">
                                 {parse(productData?.pie_content || "")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {loading && <Loading/>}

            <WinningPotential/>
        </MainWrapper>
    )
}

export default Products;