import './Styles.scss';

export const TermsAndConditions = () => {
  return (
    <div className="panel-content policy-table">
      <h3 className="mb-4 text-primary">Website Terms of Use</h3>
      <h4 className="mb-4 text-primary">Version 1.0 (07/11/2022)</h4>
      <p>
        {' '}
        Thank you for visiting our website{' '}
        <a href="http://www.inplaytip.com/">www.inplaytip.com</a> (the{' '}
        <strong>"Website"</strong>). By using the Website, you agree to comply
        with and be legally bound by the Website Terms of Use (the{' '}
        <strong>"TOU"</strong>) which shall become effective immediately upon
        your first access to the Website.
      </p>
      <p>
        We invite you to take the time necessary to familiarise yourself with
        the contents of these TOU.
      </p>
      <p>
        If you do not agree to be legal bound by all the TOU, kindly do not
        access or use the Website.
      </p>
      <h5>1. General</h5>
      <p>
        1.1. YMDM Ltd, a company incorporated under the Laws of Malta, bearing
        the company registration number C 103838 and having its registered
        address at Forth Business Centre, Level 02, Suite 9, TestaFerrata
        Street, Ta' Xbiex, Malta is the owner and operator of the Website. The
        terms “we”, “us” and “our” shall be construed accordingly.
      </p>
      <p>
        1.2. Please note that your access and use of the InPlayTip Portal is
        possible and permissible only subject to an agreement reached with the
        company that is paying for your access to the Portal.
      </p>
      <p>
        1.3. Should your company be interested in viewing the Portal to assess
        its interest in potentially subscribing to our Portal and related
        services, you may register an account to do so in terms of the{' '}
        <strong>
          <a id="termsOfServiceLink" href="#">
            Terms and Conditions
          </a>
        </strong>
        .
      </p>
      <h5>2. CONTENT AND MATERIALS</h5>
      <p>
        2.1. Content and materials available in this Website that are accessible
        without any prior account registration are made available for general
        information purposes only. You are advised to seek confirmation of any
        such content and materials before relying or acting upon them, as part
        of any interest you may have to purchase a paid subscription to the
        InplayTip Portal and related services.
      </p>
      <p>
        2.2. We shall hold no responsibility for any direct or indirect loss or
        damage which may arise in connection with the use of or reliance of any
        such materials or information on this Website. Should we provide
        hypertext links to third party website, such links shall not constitute
        an endorsement or recommendation by us, and are only provided as we
        think might be of interest to you for information purposes or
        convenience. Accordingly, your use of such links is entirely at your own
        risk and we shall have no responsibility or liability for the content,
        use, privacy notices or practices of such third party websites.
      </p>
      <h5>3. INTELLECTUAL PROPERTY</h5>
      <p>
        3.1. The Website, its intellectual property and intellectual property
        rights, including but not limited to our trademarks and copyright, are
        owned or controlled by us.
      </p>
      <p>
        3.2. You may not, and may not enable others to, copy, transmit, amend,
        reproduce, distribute, decompile, reverse engineer, disassemble, derive
        source code, decrypt, or modify the Website or any of its content or to
        create any derivative works of the Website or any of its content or
        functionality.
      </p>
      <p>
        3.3. We only allow links to (
        <a id="termsOfServiceLinkTwo" href="https://www.inplaytip.com">
          www.inplaytip.com
        </a>
        ), namely the homepage of this Website. You are not allowed to provide
        any link to any other page in this Website without our prior written
        agreement. We reserve the right to require removal of any other link to
        this Website and you hereby agree to remove such link upon request
        without undue delay.
      </p>
      <h5>4. CHANGES</h5>
      <p>
        4.1. We may change the TOU from time to time by posting an updated
        version of these TOU on the Website. Please review these TOU regularly
        to ensure you are aware of any changes made by us. Continued use of this
        Website after changes are posted signifies your agreement to be legally
        bound by the TOU as may be updated and/or amended from time to time.
      </p>
      <h5>5. GOVERNING LAW AND DISPUTES</h5>
      <p>
        5.1. These T&amp;Cs and any dispute or claim arising out of or in
        connection with these T&amp;Cs or their subject matter or formation
        (including non-contractual disputes or claims) shall be governed and
        construed in accordance with the Laws of Malta.
      </p>
      <p>
        5.2. The Courts of Malta shall have exclusive jurisdiction in relation
        to or in connection with this Website, this TOU and Privacy Notice.
      </p>
    </div>
  );
};
