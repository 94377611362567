import {useEffect} from "react";
import {resetCookieConsentValue, getCookieConsentValue} from "react-cookie-consent";

import MainWrapper from "../../Layouts/MainWrapper";

import "./Styles.scss";

const CookieConsent = () => {

	useEffect(() => {
		window.scrollTo(0, 0);
	} ,[]);

    useEffect(() => {
        const cookieConsent = getCookieConsentValue("inPlayTips-cookie-consent");


        if (cookieConsent === "false") {
            resetCookieConsentValue("inPlayTips-cookie-consent");
            window.location.reload();
        }
    }, []);
    return (
        <MainWrapper isHomepage={false} customTitle="Cookie Consent">
            <article className="mb-4 cookie-consent-container">
                <div>
                    <h3>Cookie Policy</h3>
					<p><b>Version 1.0 (15/12/2022)</b></p>
                    <p>
						Thank you for visiting the Website. Please note that our Website uses cookies.
						<br/><br/>
						This Website Cookies Policy is intended to provide you with more information 
						about how and why we use cookies on our Website. We only use cookies in the 
						manner explained in this Cookies Policy.
                    </p>

                    <p>
                        <b>1. What are Cookies?</b> <br/>
                        "Cookies" are small pieces of data that our Website transfers to the web browser
						 on your electronic device (be it mobile, computer or other device) when you visit 
						 our Website. These are then sent back from your device to our Website on each 
						 subsequent visit for a number of reasons depending on the respective cookie.
                    </p>

                    <p>
                        <b>2. What Cookies do we use?</b><br/>
                        Please note that our Website uses only one type of cookie, details of which are set out below:
                    </p>

                    <p>
                        <table>
							<tr>
								<th>
									Provenance
								</th>
								<th>
									Name
								</th>
								<th>
									Type
								</th>
								<th>
									Essential for website to function?
								</th>
								<th>
									Duration
								</th>
								<th>Control/Access</th>
								<th>Purpose</th>
							</tr>
							<tr>
								<td>
									Google Analytics
								</td>
								<td>
									_ga
								</td>
								<td>
									Persistent
								</td>
								<td>
									No								</td>
								<td>
									2years
								</td>
								<td>PromoTron</td>
								<td>For statistics and to uniquely identify users accessing the website www.inplaytip.com</td>
							</tr>
						</table>
                    </p>

                    <p>
                        <b>3. Cookie Consent</b><br/>
                        When visiting our Website, a pop-up will appear and you will be requested to either 
						(i) Accept Cookies, or (ii) Reject Cookies.<br/><br/>
                        The _ga cookie shall be placed on your devices only if you click Accept Cookies.
                    </p>

                    <p>
                        <b>4. How can I control the cookie?</b><br/>
                        You may control your cookie preferences after your original choice on accessing our Website, 
						depending on the web-browser you use to access our Website.
						<br/><br/>
						You can change the settings of your web browser to block/delete the cookies at will.
						<br/><br/>
						To find out more out more on how to manage cookies, you may wish to visit websites 
						such as www.allaboutcookies.org.

                    </p>

                    <p>
                        <b>5. How to Delete Cookies?</b><br/>
                        If you want to restrict or block the cookies that are set by our Website following your 
						consent, you can do so through your browser or device settings.
						<br/><br/>
                        Comprehensive information about how to delete or restrict cookies can be found online 
						specifically regarding the type of browser or device which you use to access the Website.
                    
                    </p>
					<p>
                        <b>6. Updates</b><br/>
                        This Website Cookies Policy may be updated from time to time in our sole discretion. If we change our Cookies Policy, 
						you will be asked to consent again to our update Cookies Policy on your next visit to our Website.
                    
                    </p>
					<p>
                        <b>7. Contacting Us</b><br/>
                        If you wish to contact us regarding our cookies or this Cookies Policy, 
						please do not hesitate to do so by email on support@inplaytip.com.
                    </p>
                </div>
            </article>
        </MainWrapper>
    );
};

export default CookieConsent;
