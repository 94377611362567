import envConfig from "../Configs/env.config";
import IServiceProvider from "./IServiceProvider";
import BaseService from "./BaseService";

class AccountingBaseService extends BaseService implements IServiceProvider {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor() {
        super();
    }

    async initialize() {
        this.baseUrl = envConfig.REACT_APP_ACCOUNTING_BASE_URL;
    }
}

export default AccountingBaseService;
