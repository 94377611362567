import React from "react";
import {useLocation} from "react-router-dom";

import TelegramIcon from "../../../Assests/Images/telegramIcon.png";

import MainWrapper from "../../../Layouts/MainWrapper";

import {TELEGRAM_URLS} from "../../../Constants/Values";

import "./Styles.scss";

const {
    REACT_APP_ENV
} = (window as any).__env__;

const TelegramSteps = () => {
    const {state} = useLocation();

    const telegramLinkHandler = () => {
        if (REACT_APP_ENV === "dev") {
            window.open(TELEGRAM_URLS.dev, "_blank");
        } else if (REACT_APP_ENV === "staging") {
            window.open(TELEGRAM_URLS.staging, "_blank");
        } else if (REACT_APP_ENV === "qa") {
            window.open(TELEGRAM_URLS.qa, "_blank");
        } else {
            window.open(TELEGRAM_URLS.prod, "_blank");
        }
    }

    return (
        <MainWrapper isHomepage={false} customTitle="Telegram">
            <div className="telegram-container">
                <div className="header-container text-black d-flex align-items-center"></div>

                <div className="telegram-body">
                    <h3>
                        Success!
                    </h3>
                    <p>
                        Our tips are sent via Telegram click below.
                    </p>


                    <div className="d-flex align-items-center justify-content-center">
                        <div className="telegram-center-button" onClick={() => telegramLinkHandler()}>
                            <span>Start receiving tips</span>
                        </div>
                        <img src={TelegramIcon} onClick={() => telegramLinkHandler()} className="telegram-icon" alt="telegram"/>
                    </div>


                </div>

            </div>

        </MainWrapper>
    );
}

export default TelegramSteps;