import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Divider, Spin} from "antd";
import {SubmitHandler, useForm} from "react-hook-form";

import Input from "../../Components/Forms/Input/Input";

import AccountingService from "../../Services/Accounting/AccountingService";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Toast from "../../Utils/ToastHandler";
import {TOAST_BOTTOM_CENTER, TOAST_DURATION} from "../../Constants/Values";
import AssetsService from "../../Services/Assets/AssetsService";

import "./Styles.scss";
import TokenHandler from "../../Utils/TokenHandler";
import {gtagEventHandler} from "../../Utils/gtagEventHandler";
import {CloseOutlined} from "@ant-design/icons";
import {ROUTE_CONSTANTS} from "../../Routes/RouteConstants";

import {ReactComponent as LockIcon} from "../../Assests/Icons/lock.svg";

type topUpPayloadType = {
    totalAmount: number;
    countryIso2?: string;
    paymentMethod: string;
    credit: number;
}

type TopUpType = {
    amount: number;
    paymentMethods: any;
    creditRate: number;
    taxRate: string;
    currency: string;
    credit: number;
}

const TopUp = () => {

    const {t} = useTranslation();
    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: {errors},
    } = useForm<TopUpType>({
        mode: 'onBlur',
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [topUp, setTopUp] = useState<TopUpType>({
        amount: 0.00,
        paymentMethods: {},
        creditRate: 0,
        taxRate: "",
        currency: "EUR",
        credit: 0,
    });
    const [step, setStep] = useState<number>(1);
    const [externalUrl, setExternalUrl] = useState<string>("");
    const [countryIso2, setCountryIso2] = useState<string>("");

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>("1");


    const getRegion = () => {
        setIsLoading(true);
        AssetsService.getRegion().then((res: any) => {
            const countryRegion = res.region;

            setCountryIso2(countryRegion);

            AccountingService.getTopUpPreview(countryRegion).then((response) => {
                setTopUp({
                    ...topUp,
                    paymentMethods: response.paymentMethods,
                    creditRate: response.creditRate,
                    taxRate: response.vat,
                    currency: response.currencyIso3,
                });

                setIsLoading(false);
            }).catch(() => {
                setIsLoading(false);
                setStep(3);
            });
        }).catch(e => {
            setIsLoading(false);
            setStep(3);
        })
    }

    const submitForm: SubmitHandler<TopUpType> = () => {

        setIsLoading(true);
        const payload: topUpPayloadType = {
            totalAmount: topUp.amount,
            countryIso2: countryIso2,
            credit: topUp.credit,
            paymentMethod: selectedPaymentMethod,
        };

        AccountingService.topUp(payload).then((response) => {
            const redirectUrl = response.url;
            setExternalUrl(redirectUrl);
            setStep(2);
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);

            const error = err.response.data;

            Toast.error(
                error ? t(error) : t("generic-error"),
                TOAST_DURATION,
                TOAST_BOTTOM_CENTER
            );
        });
    }

    const totalCostHandler = (type: string) => {
        const tax = topUp.amount * parseInt(topUp.taxRate) / 100;
        const creditCost = topUp.credit * topUp.creditRate;

        switch (type) {
            case "total":
                return (creditCost.toFixed(2) || 0);
            case "tax":
                return tax.toFixed(2);
            case "cost":
                return (creditCost - tax).toFixed(2);
            default:
                return 0.0;
        }
    }

    const topupFormHandler = () => {
        return <form onSubmit={handleSubmit(submitForm)} className="col-md-8">
            <div className="row">
                <label htmlFor="credit" className="font-sans text-sm font-normal text-light-black tracking-wider mb-2.5">
                    {t("Topup-Credit-Rate")}
                </label>
                <Input
                    id="credit"
                    name="credit"
                    type="number"
                    min="0"
                    value={String(topUp.credit)}
                    withMargin={false}
                    required={true}
                    customRef={register("credit", {
                        onChange: (e) => {
                            const totalAmount = parseInt(e.target.value) * topUp.creditRate;

                            setTopUp({
                                ...topUp,
                                credit: parseInt(e.target.value) || 0,
                                amount: totalAmount || 0.00,
                            })

                            gtagEventHandler({
                                event_category: "Topup",
                                event_label: "Credit",
                                value: parseInt(e.target.value),
                            }, "topup_credit");

                        },
                        required: {
                            value: true,
                            message: t("Topup-Credit-Required"),
                        },
                        min: {
                            value: "1",
                            message: t("Topup-Incorrect-Credit"),
                        },
                        pattern: {
                            value: /^[0-9]+$/,
                            message: t("Topup-Incorrect-Credit"),
                        }
                    })}
                    error={errors.credit?.message}
                    labelType={"none"}
                    divClassName="col-md-12"
                    inputClassName="form-control font-sans text-sm font-normal text-light-black tracking-wider px-4 py-3"
                />
            </div>

            <div className="mt-2">
                <span className="font-sans text-sm tracking-wider font-normal text-gray-darker">Price Breakdown</span>
            </div>

            <div className="d-flex flex-row justify-content-between align-items-center mt-3">
                <span className="font-sans text-sm tracking-wider font-normal text-gray-darker">{topUp.credit + " " + t("Topup-Credit-Rate")}{(topUp.credit > 1) ? "s" : ""}</span>
                <strong className="font-sans text-sm tracking-wider font-bold text-light-black">{topUp.currency + " " + totalCostHandler("cost")}</strong>
            </div>

            <div className="d-flex flex-row justify-content-between align-items-center">
                <span className="font-sans text-sm tracking-wider font-normal text-gray-darker">{t("Topup-Tax-Rate") + " " + topUp.taxRate + "%"}</span>
                <strong  className="font-sans text-sm tracking-wider font-bold text-light-black">{
                    topUp.currency + " " + totalCostHandler("tax")
                }</strong>
            </div>

            <Divider dashed={true}/>

            <div className="d-flex flex-row justify-content-between align-items-center">
                <span className="font-sans text-sm tracking-wider font-normal text-gray-darker">{t("Topup-Total-Cost")}</span>
                <strong className="font-sans text-sm tracking-wider font-bold text-light-black">{topUp.currency} {totalCostHandler("total")}</strong>
            </div>

            <Divider  dashed={true}/>
            <div className="col-md-12">
                <label htmlFor="paymentMethod" className="ffont-sans text-sm font-normal text-light-black tracking-wider mb-2.5">
                    {t("Topup-Payment-Method")}
                </label>
                <Dropdown
                    selectclass="form-select font-sans text-xs font-normal text-light-black tracking-wider"
                    style={{
                        fontSize: "14px",
                        padding: "18px 16px",
                    }}
                    id="paymentMethod"
                    name="paymentMethod"
                    onChange={(e) => {
                        setSelectedPaymentMethod(e.target.value);

                        gtagEventHandler({
                            event_category: "Topup",
                            event_label: "Payment Method",
                            value: e.target.value,
                        }, "topup_payment_method");
                    }}
                    value={selectedPaymentMethod}
                    options={
                        topUp.paymentMethods && Object.keys(topUp.paymentMethods).map((key: any) => {
                                return {
                                    name: topUp.paymentMethods[key],
                                    value: key,
                                };
                            }
                        )
                    }
                />
            </div>

            <div className="flex items-center flex-col justify-center pt-14">
                <button
                    id="saveButton"
                    type="submit"
                    className="flex bg-lemon-green items-center justify-center text-white rounded-full px-14 py-2.5"
                    disabled={isLoading}
                    onClick={() => {
                        gtagEventHandler({
                            event_category: "Topup",
                            event_label: "Proceed Payment",
                        }, "topup_proceed_payment");
                    }}
                >
                    {t("Topup-Proceed-Payment")}
                </button>

                <div className="mt-6 flex items-center">
                    <LockIcon />
                    <div className="flex flex-col ms-2">
                        <span className="font-sans text-xs font-light text-gray-darker tracking-wider">Your payment is secure and protected</span>
                        <span className="font-sans text-xs font-light text-gray-darker tracking-wider">with advanced encryption technology</span>
                    </div>
                </div>
            </div>

        </form>
    }

    const paymentPageHandler = () => {
        if (!externalUrl) {
            return <div className="col-md-12">
                <div className="alert alert-danger d-flex justify-content-center flex-column align-items-center"
                     role="alert">
                    {t("Topup-Redirect-Payment-Error")}
                </div>
            </div>
        } else {
            window.open(externalUrl, "_self");
        }

    }

    const IncompleteProfileHandler = () => {
        return <div className="col-md-8">
            <div className="alert alert-warning d-flex justify-content-center flex-column align-items-center"
                 role="alert">
                {t("Topup-Incomplete-Profile")}

                <Button
                    type="primary"
                    className="save-button my-4"
                    onClick={() => {
                        gtagEventHandler({
                            event_category: "Topup",
                            event_label: "Incomplete Profile",
                        }, "topup_incomplete_profile");

                        window.location.href = `${ROUTE_CONSTANTS.DASHBOARD}?tab=profile`
                    }}
                >
                    {t("Topup-Goto-Profile")}
                </Button>
            </div>
        </div>
    }

    const topUpStepHandler = () => {
        switch (step) {
            case 1:
                return topupFormHandler();
            case 2:
                return paymentPageHandler();
            case 3:
                return IncompleteProfileHandler();
            default:
                return topupFormHandler();
        }
    }

    useEffect(() => {
        getRegion();

        gtagEventHandler({
            event_category: "Topup",
            event_label: "Topup Page",
            page_path: window.location.pathname,
        }, "topup_page");
    }, []);

    return (
        <div className="xl:row ">
            <div className="hidden xl:flex justify-between col-md-10 ">
                <h5
                    className="text-5xl text-gray-dark font-black font-sans my-10"
                >
                    Top up
                </h5>

                <CloseOutlined
                    rev={undefined}
                    onClick={() => {
                        window.location.href = ROUTE_CONSTANTS.DASHBOARD;
                    }}
                    className="hand-pointer"
                />
            </div>

            <div className="xl:m-auto xl:mt-4 col-md-7 xl:py-1     py-20 px-4 mt-[-6rem] bg-white rounded-t-[20px]">
                {!isLoading && topUpStepHandler()}
            </div>

            {isLoading && <div className="spinner-container xl:col-md-8 backdrop-blur-md">
                <Spin size="large"/>
            </div>}
        </div>
    );
}

export default TopUp;