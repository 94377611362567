import {PhoneAuthInterface, UserToken} from "../../Models/Auth";
import Token from "../../Utils/TokenHandler";
import AuthBaseService from "../AuthBaseService";
import FactoryService from "../FactoryService";

const factoryService = new FactoryService();

class AuthService {
    private GET_TOKEN_ENDPOINT: string = "connect/token";
    private PHONE_AUTHORIZATION: string = "Auth/device/authorization";
    private PHONE_REGISTRATION: string = "Auth/device/registration";
    private EMAIL_ENDPOINT: string = "Users/email";
    private EMAIL_UPDATE_ENDPOINT: string = "Users";

    async refreshToken(): Promise<UserToken> {
        try {
            const response = await (
                await factoryService.create(AuthBaseService)
            ).postAsJson(
                this.GET_TOKEN_ENDPOINT,
                {
                    grant_type: "refresh_token",
                    refresh_token: Token.getRefreshToken(),
                },
                false,
            );

            Token.setToken(response.access_token);
            Token.setRefreshToken(response.refresh_token);

            return response;
        } catch (error) {
            throw error;
        }
    }

    async authorizePhoneNumber(phone: string) {
        const payload: PhoneAuthInterface = {
            "ClientId": "inplaytipsdevice",
            "Scopes": ["openid", "offline_access"],
            "PhoneNumber": phone
        }

        return await (
            await factoryService.create(AuthBaseService)
        ).postAsJson(this.PHONE_AUTHORIZATION, payload, false);
    }

    async registerPhoneNumber(phone: string, email: string) {
        const payload = {
            "phoneNumber": phone,
            "email": email
        }

        return await (
            await factoryService.create(AuthBaseService)
        ).postAsJson(this.PHONE_REGISTRATION, payload, false);
    }

    async verifyOTP(otp: string, phone: string) {

        try {
            const response = await (
                await factoryService.create(AuthBaseService)
            ).postAsJson(
                this.GET_TOKEN_ENDPOINT,
                {
                    username: phone,
                    device_code: otp,
                    grant_type: 'urn:ietf:params:oauth:grant-type:device_code',
                },
                false,
            );

            Token.setToken(response.access_token);
            Token.setRefreshToken(response.refresh_token);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getEmail() {
        return await (
            await factoryService.create(AuthBaseService)
        ).getAsJson(this.EMAIL_ENDPOINT, true);
    }

    async updateEmail(payload: any) {

        return await (
            await factoryService.create(AuthBaseService)
        ).patchAsJson(this.EMAIL_UPDATE_ENDPOINT, payload, true);
    }

}

export default new AuthService();
