import React from "react";

import Accordion from "../../Accordion/Accordion";

import "./Styles.scss"

type Props = {
    faqData: any;
}

const FaqSection = ({faqData}: Props) => {

    return (
        <div className="faq-container">
            <div className="container">
                <div className="mt-8 xl:mt-32 lg:mx-24 py-20 lg:py-32">
                    <h3 className="text-4xl text-light-black font-black font-sans mb-10">FAQ</h3>

                    <div className="faq-list">
                        {
                            faqData?.map((faq: {
                                translations: { answer: string; question: string; }[];
                            }, index: React.Key | null | undefined) => {
                                return (
                                    <Accordion
                                        key={index}
                                        title={faq.translations[0].question}
                                        content={faq.translations[0].answer}
                                    />
                                )
                            })
                        }
                    </div>

                </div>
            </div>
        </div>

    )
}

export default FaqSection;
