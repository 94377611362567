import SlidingPane from "react-sliding-pane";

import { TermsAndConditions as Terms } from "../../Components/TermsAndConditions";

import { ReactComponent as CloseIcon } from "../../Assests/Icons/close.svg";

import { useTranslation } from "react-i18next";

import { MOBILE_MAX_WIDTH_QUERY } from "../../Constants/Values";
import useMatchMedia from "../../Hooks/useMatchMedia";
import "./styles.scss";

type Props = {
  id: string;
  isOpened: boolean;
  setIsOpened: any;
};

const TermsOfService = ({ isOpened, id, setIsOpened }: Props) => {
  const { t } = useTranslation();
  const { match: isMobileDevice } = useMatchMedia(MOBILE_MAX_WIDTH_QUERY);

  return (
    <SlidingPane
      isOpen={isOpened}
      hideHeader={true}
      width={isMobileDevice ? "100%" : "50%"}
      className={"slidingPanel"}
      onRequestClose={() =>
        setIsOpened(!isOpened)
      }
    >
      <div className="panel" id={id}>
        <div className="position-absolute top-0 end-0 p-3">
          <button
            className="btn btn-close"
            data-close-panel="termsPanel"
            onClick={() =>
              setIsOpened(!isOpened)
            }
          >
            {/*<CloseIcon />*/}
          </button>
        </div>
        <Terms />
      </div>
    </SlidingPane>
  );
};

export default TermsOfService;
