import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {ROUTE_CONSTANTS} from "../../../Routes/RouteConstants";
import TermsOfService from "../../../Pages/Terms";
import envConfig from "../../../Configs/env.config";
import {ReactComponent as LogoWhiteIcon} from "../../../Assests/Images/logo-white.svg";


import "./Styles.scss";

const Footer = () => {
    const year = new Date().getFullYear();
    const [isTermsOpened, setIsTermsOpened] = useState<boolean>(false);
    const [footerLinks, setFooterLinks] = useState<any>(null);

    const fetchFooter = async () => {
        const cache = await caches.open("layoutContents");

        const response = await cache.match("layoutContents");

        if (response) {
            const data = await response.json();

            setFooterLinks(data?.footermenu || null)
        } else {
            let retryCounter = 0;

            const retryFetch = async () => {
                if (retryCounter < 1) {
                    retryCounter++;
                    fetchFooter();
                }
            }

            retryFetch();
        }

    }

    useEffect(() => {
        fetchFooter();
    }, []);

    return (
        <div className="footer-container">

            <div className="container">
                <h5 className="text-white font-sans text-2xl font-bold"><LogoWhiteIcon/></h5>

                <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
                    <div className="flex flex-row gap-5 my-9 flex-wrap">

                        {
                            footerLinks?.map((link: any, index: number) => {
                                return (
                                    <Link
                                        key={index}
                                        className="text-white font-sans text-base font-normal leading-6"
                                        to={link.translations[0].slug}>
                                        {link.translations[0].name}
                                    </Link>
                                )

                            })
                        }
                    </div>

                    <div className="flex flex-row gap-3 mb-7">
                        <a href="https://www.facebook.com/inplaytip" target="_blank" rel="noreferrer">
                            <i className="bi-facebook text-white"></i>
                        </a>
                        <a href="https://www.linkedin.com/company/inplaytip/" target="_blank" rel="noreferrer">
                            <i className="bi-linkedin text-white"></i>
                        </a>

                    </div>
                </div>
            </div>
            <hr/>
            <div className="container">
                <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
                    <div className="flex flex-row gap-5 mt-6">
                        <Link id="footer-terms-and-conditions"
                              to={ROUTE_CONSTANTS.TERMS_AND_CONDITIONS}
                              className="text-white font-sans text-base font-normal leading-6"
                              onClick={
                                  (e) => {
                                      e.preventDefault();
                                      setIsTermsOpened(true);
                                  }

                              }
                        >
                            Terms and Conditions
                        </Link>
                        <Link
                            id="footer-privacy-policy"
                            className="text-white font-sans text-base font-normal leading-6"
                            to={ROUTE_CONSTANTS.PRIVACY_POLICY}>
                            Privacy Policy
                        </Link>
                    </div>
                    <span className="text-white font-sans text-base font-normal leading-6 mt-6">
                        Copyright &copy; InPlayTip {year} - Version: {envConfig.REACT_APP_VERSION}
                    </span>
                </div>

                <TermsOfService
                    id="termsOfService"
                    isOpened={isTermsOpened}
                    setIsOpened={setIsTermsOpened}
                />
            </div>


        </div>
    )
}

export default Footer;