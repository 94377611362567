import {ReactComponent as BlogSearch} from "../../../Assests/Icons/search-blog.svg";
import {ReactComponent as BlogRightArrow} from "../../../Assests/Icons/blog-right-arrow.svg";
import {ReactComponent as BlogLeftArrow} from "../../../Assests/Icons/blog-left-arrow.svg";
import {useNavigate, useParams} from "react-router-dom";
import WinningPotential from "../../Sections/WinningPotential/WinningPotential";
import React from "react";
import envConfig from "../../../Configs/env.config";
import parse from "html-react-parser";
import {ROUTE_CONSTANTS} from "../../../Routes/RouteConstants";

type PaginationProps = {
    totalItemCount: number,
    pageNumber: number,
    pageSize: number
}

type Props = {
    articles: any
    paginationData: PaginationProps,
    setPaginationData: any,
    searchKey: string,
    setSearchKey: any,
    setSortOrder: any,
    sortOrder: string
}


const BlogListNew = ({articles, paginationData, setPaginationData, searchKey, setSearchKey, setSortOrder, sortOrder}: Props) => {
    const navigate = useNavigate();
    const {pageNumber} = useParams();

    return (<div>
            <div className="flex items-center flex-col justify-center">
                <h5 className="bg-light-green text-dark-green text-2xl font-sans font-normal leading-6 px-6 py-2 rounded-md">Blog</h5>
                <h5 className="deep-black font-bold font-sans text-4xl mt-7 text-center mb-6">Our Blog and
                    Highlight</h5>
               

                <div
                    className="flex items-center flex-row justify-center w-full lg:w-1/3 my-8 px-3 py-2 rounded-lg bg-gray-light bg-white-darker">
                    <BlogSearch className="me-2"/>
                    <input
                        type="search"
                        value={searchKey}
                        onChange={(e) => setSearchKey(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                navigate(`${ROUTE_CONSTANTS.BLOG}?search=${searchKey}`)
                            }
                        }}
                        placeholder="Search for any blog article"
                        className="w-full bg-transparent p-1 deep-black font-sans font-normal text-xl opacity-50 placeholder:text-[#131313] placeholder:font-sans placeholder:font-normal placeholder:text-base xl:placeholder:text-lg"
                    />
                </div>
            </div>

            <hr className="hidden xl:block divide-y-2 divide-gray-light xl:mb-20 mt-14"/>

            <div className="flex items-center justify-between xl:mb-10">
                <div className="flex items-center gap-4 justify-center">
                    {/*<h5 className="text-orange font-sans font-normal text-base py-1 px-3 bg-light-orange rounded-full">All</h5>*/}
                    {/*<h5 className="text-deep-black font-sans font-normal text-base">Trending</h5>*/}
                    {/*<h5 className="text-deep-black font-sans font-normal text-base">Update</h5>*/}
                    {/*<h5 className="text-deep-black font-sans font-normal text-base">Client</h5>*/}
                </div>

                <div className="w-full md:w-1/3 flex items-center justify-end">
                    <select
                        className="w-full md:w-3/4 bg-white p-2 rounded-lg font-sans text-deep-black font-normal text-base border-1 border-gray-dark"
                        onChange={(e) => setSortOrder(e.target.value)}
                        value={sortOrder}
                    >
                        <option value="-published_date">Recently</option>
                        <option value="published_date">Oldest</option>
                    </select>
                </div>
            </div>

            <hr className=" xl:hidden divide-y-2 divide-gray-light mb-3"/>

            {!articles.length ? <div
                    className="text-xl text-center p-32"
                >
                    <h4>Article Not Found</h4>
                </div> :
                <div>

                    <div className="grid lg:grid-cols-3 gap-y-4 gap-x-8">
                        {
                            articles && articles.map((article: any, index: number) => {
                                return (
                                    <div
                                        key={index}
                                        className="rounded-lg pb-7">
                                        {
                                            (article.image?.id) ?
                                                <img
                                                    src={`${envConfig.REACT_APP_DIRECTUS_BASE_URL}assets/${article.image?.id}`}
                                                    alt={article?.translations[0]?.title}
                                                    className="w-full h-48 object-cover rounded-lg mb-3"
                                                /> :
                                                <div className="w-full h-48 bg-gray-dark rounded-lg mb-3"/>
                                        }

                                        <h5 className="text-deep-black font-sans font-normal  px-3">
                                            <span className="text-orange text-sm me-3">Updated</span>
                                            <span className="opacity-50 text-sm">{
                                                new Date(article.published_date).toLocaleDateString('en-US', {
                                                    month: 'long',
                                                    day: '2-digit',
                                                    year: 'numeric'
                                                })
                                            }</span>
                                        </h5>
                                        <h5 className="text-deep-black font-sans font-bold text-xl mb-3  px-3" title={
                                            article?.translations[0]?.title
                                        }>
                                            {
                                                article?.translations[0]?.title.length > 40 ?
                                                    article?.translations[0]?.title.substring(0, 40) + " ..." :
                                                    article?.translations[0]?.title
                                            }
                                        </h5>
                                        <h5 className="text-deep-black font-sans font-normal text-lg mb-3 opacity-50  px-3">
                                            {
                                                parse(article?.translations[0]?.content?.substring(0, 150) + " ...")
                                            }
                                        </h5>

                                        <a
                                            className="bg-transparent border-1 border-gray-dark text-deep-black font-sans font-semibold text-base p-2.5 rounded-md flex mx-3 w-36 justify-center"
                                            href={`${ROUTE_CONSTANTS.BLOG}/${article.slug}`}
                                        >
                                            <span>Read More</span>
                                            <BlogRightArrow className="ms-2"/>
                                        </a>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <hr className="divide-y-2 divide-gray-light mb-20 mt-14"/>

                    <div>
                        <div className="flex items-center lg:justify-between xl:mb-28 flex-wrap">
                            <a
                                className={`bg-transparent border-1 border-gray-dark text-deep-black font-sans font-normal text-base p-2.5 rounded-md me-3 flex ${paginationData.pageNumber === 1 ? "opacity-50 pointer-events-none" : ""}`}
                                href={`${ROUTE_CONSTANTS.BLOG}/${ paginationData.pageNumber === 1 ? 1 : paginationData.pageNumber - 1}`}
                            >
                                <BlogLeftArrow/>
                                Previous
                            </a>

                            <div className="flex items-center gap-2">
                                {
                                    Array.from({length: Math.ceil(paginationData.totalItemCount / paginationData.pageSize)}, (_, i) => {
                                        return (
                                            <a
                                                key={i}
                                                className={`text-deep-black font-sans font-normal text-base px-3 py-2 rounded-full me-3 ${paginationData.pageNumber === i + 1 ? "bg-gray-dark" : ""}`}
                                                href={`${ROUTE_CONSTANTS.BLOG}/${i + 1}`}
                                            >
                                                {i + 1}
                                            </a>
                                        )
                                    })
                                }
                            </div>

                            <a
                                className={`bg-transparent border-1 border-gray-dark text-deep-black font-sans font-normal text-base p-2.5 rounded-md me-3 mt-1 flex ${paginationData.pageNumber === Math.ceil(paginationData.totalItemCount / paginationData.pageSize) ? "opacity-50 pointer-events-none" : ""}`}
                                href={`${ROUTE_CONSTANTS.BLOG}/${paginationData.pageNumber + 1}`}
                            >
                                Next
                                <BlogRightArrow className="ms-2"/>
                            </a>
                        </div>
                    </div>

                    <WinningPotential/>
                </div>}
        </div>
    );
}

export default BlogListNew;